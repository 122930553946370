<template>
    <div class="flex flex-col justify-start items-center w-full h-full cursor-pointer bg-[#002F6C] text-[#fff]">
        <div v-if="dashboadrs.length > 0" class="text-[1.6rem] text-[#fff] pt-[2.8rem] pb-[1rem] w-full pl-[1.6rem] flex flex-row items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[1.8rem] mr-[0.8rem]">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
            </svg>
        <span class="leading-[1.4rem]">대시보드</span>
        </div>
        <div class="flex flex-col items-start justify-center w-full h-auto leading-[2.8rem] gap-[0.4rem]">
            <div class="select-none flex flex-row items-center text-[1.4rem] ml-[4rem] w-full" v-for="item in dashboadrs" :key="item">
                <div v-if="selItemName.id === item.id"></div>
                <div :style="{color : selItemName.id === item.id ? '#ffffff' : 'rgba(255,255,255,0.8)', background: selItemName.id === item.id ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName.id === item.id ? '148px' : 'auto',
            position:selItemName.id === item.id ? 'relative' : 'unset', padding:selItemName.id === item.id ? '0 24px' : '0'}" class="w-full rounded-lg text-[1.4rem] h-[3rem] right-[2.5rem] font-light mr-[3rem]" @click="onItemClick(item)">{{item.name}}</div>
            </div>
        </div>
        <div v-if="infos.length > 0" class="text-[1.6rem] text-[#fff] pt-[1.7rem] pb-[1rem] w-full pl-[1.6rem] flex flex-row items-center">
            <img src="../assets/icon/NavigationList_icon_01.png" class="w-[1.6rem] mr-[0.8rem]">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg> -->
            <span class="leading-[1.6rem]">현황</span>
        </div>
        <div class="flex flex-col items-start w-full h-auto leading-[2.8rem] gap-[0.4rem]">
            <div class="select-none flex flex-row items-center text-[1.4rem] ml-[4rem] w-full" v-for="item in infos" :key="item">
                <div v-if="selItemName.id === item.id"></div>
                <div :style="{color : selItemName.id === item.id ? '#ffffff' : 'rgba(255,255,255,0.8)', background: selItemName.id === item.id ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName.id === item.id ? '148px' : 'auto',
                    position:selItemName.id === item.id ? 'relative' : 'unset', padding:selItemName.id === item.id ? '0 24px' : '0'}" class="w-full rounded-lg text-[1.4rem] h-[2.9rem] right-[2.5rem] font-light mr-[3rem]" @click="onItemClick(item)">{{item.name}}</div>
                </div>
        </div>
        <div v-if="manages.length > 0" class="text-[1.6rem] text-[#fff] pt-[1.7rem] pb-[1rem] w-full pl-[1.6rem] flex flex-row items-center">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg> -->
            <img src="../assets/icon/NavigationList_icon_02.png" class="w-[1.6rem] mr-[0.8rem]">
            <span>관리</span>
        </div>
        <div class="flex flex-col items-start w-full h-auto leading-[2.8rem]">
            <div class="select-none flex flex-row items-center text-[1.4rem] ml-[4rem] w-full" v-for="item in manages" :key="item">
                <div v-if="selItemName.id === item.id"></div>
                <div :style="{color : selItemName.id === item.id ? '#ffffff' : 'rgba(255,255,255,0.8)', background: selItemName.id === item.id ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName.id === item.id ? '148px' : 'auto',
                    position:selItemName.id === item.id ? 'relative' : 'unset', padding:selItemName.id === item.id ? '0 24px' : '0'}" class="w-full rounded-lg text-[1.4rem] h-[2.9rem] right-[2.5rem] font-light mr-[3rem]" @click="onItemClick(item)">{{item.name}}</div>
                </div>
        </div>
        <div v-if="sys.length > 0" class="text-[1.6rem] text-[#fff] pt-[1.7rem] pb-[1rem] w-full pl-[1.6rem] flex flex-row items-center">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg> -->
            <img src="../assets/icon/NavigationList_icon_03.png" class="w-[1.6rem] mr-[0.8rem]">
            <span>시스템</span>
        </div>
        <div class="flex flex-col items-start w-full h-auto leading-[2.8rem] gap-[0.4rem]">
            <div class="select-none flex flex-row items-center text-[1.4rem] ml-[4rem] w-full" v-for="item in sys" :key="item">
                <div v-if="selItemName.id === item.id"></div>
                <div :style="{color : selItemName.id === item.id ? '#ffffff' : 'rgba(255,255,255,0.8)', background: selItemName.id === item.id ? '#006EDD' : 'rgba(0,0,0,0)', width : selItemName.id === item.id ? '148px' : 'auto',
                position:selItemName.id === item.id ? 'relative' : 'unset', padding:selItemName.id === item.id ? '0 24px' : '0'}" class="w-full rounded-lg text-[1.4rem] h-[3rem] right-[2.5rem] font-light mr-[3rem]" @click="onItemClick(item)">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import RestHelper from './Helpers/RestHelper'

export default {
    components: {
    },
    data() {
        return {
            infos : [
                {id:'B0010',name: '수주', type : 'info',tabName : '현황 > 수주'},
                {id:'B0020',name: '수금', type : 'info',tabName : '현황 > 수금'},
                {id:'B0030',name: '비용', type : 'info',tabName : '현황 > 비용'},
                {id:'B0040',name: '배분', type : 'info',tabName : '현황 > 배분'},
                {id:'B0050',name: '매출', type : 'info',tabName : '현황 > 매출'},
                {id:'B0060',name: '인원', type : 'info',tabName : '현황 > 인원'},
                {id:'B0070',name: '세부UR', type : 'info',tabName : '현황 > 세부UR'},
            ],
            manages : [
                {id:'C0010',name: '수주', type : 'manage' ,tabName : '관리 > 수주'},
                {id:'C0020',name: '수금', type : 'manage' ,tabName : '관리 > 수금'},
                {id:'C0030',name: '비용', type : 'manage' ,tabName : '관리 > 비용'},
                {id:'C0040',name: '배분', type : 'manage',tabName : '관리 > 배분'},
                {id:'C0050',name: '단가', type : 'manage',tabName : '관리 > 단가'},
                {id:'C0060',name: '매출', type : 'manage',tabName : '관리 > 매출'},
                {id:'C0070',name: '인원', type : 'manage',tabName : '관리 > 인원'},
                {id:'C0080',name: '목표', type : 'manage',tabName : '관리 > 목표'},
            ],
            sys : [
                {id:'D0010',name: '부서', type : 'manage',tabName : '시스템 > 부서'},
                {id:'D0020',name: '인원', type : 'manage',tabName : '시스템 > 인원'},
                {id:'D0030',name: '권한', type : 'manage',tabName : '시스템 > 권한'},
            ],
            dashboadrs: [
                {id:'A0010',name: '실적현황', type : 'manage',tabName : '대시보드 > 실적현황'},
                {id:'A0020',name: 'UR현황', type : 'manage',tabName : '대시보드 > UR현황'},
                {id:'A0030',name: '성과총괄표', type : 'manage',tabName : '대시보드 > 성과총괄표'},
            ],
            selItemName : {},
        };
    },
    mounted()
    {
        this.$nextTick(()=>{
            this.onItemClick(this.dashboadrs[0])
        })
    },
    async created(){
        const res = await RestHelper.RestSend('USP_MENU','MENU',[{ MENU_SQ : window.localStorage.getItem('userKey')}])
        if(res._result[0].RESULTCD == '1')
        {
            const data = [...res._data]
            for(const o of this.infos.concat(this.manages).concat(this.sys).concat(this.dashboadrs))
            {
                const target = data.find(x=>x.MENU_CODE === o.id);
                o.order = target?.ORDER_CN ?? 999;
            }
            const menus = [...data.map(x=>x.MENU_CODE)]
            menus.push('A0010');
            menus.push('A0020');
            menus.push('A0030');
            this.infos = this.infos.filter(x=>menus.find(o=>o === x.id)).sort((a,b)=>{return a.order - b.order});
            this.manages = this.manages.filter(x=>menus.find(o=>o === x.id)).sort((a,b)=>{return a.order - b.order});
            this.sys = this.sys.filter(x=>menus.find(o=>o === x.id)).sort((a,b)=>{return a.order - b.order});
            this.dashboadrs = this.dashboadrs.filter(x=>menus.find(o=>o === x.id)).sort((a,b)=>{return a.order - b.order});
        }
    },
    methods:{
        onItemClick(itemName)
        {
            this.selItemName = itemName;
            this.$emit('onChangeItem',itemName)
        },
    }
};
</script>

<style scoped>
</style>
      