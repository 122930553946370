<template>
  <table class="table border-2 text-center text-[1.2rem] w-full">
    <tr class="table-row border-[2px] bg-[#f5f5f5]">
        <td class="table-cell border-[2px] h-[4.7rem] text-[#2B3133]" colspan="2">부서</td>
        <td class="table-cell border-[2px] h-[4.7rem] text-[#2B3133]" v-for="col in tableCols" :key="col">{{ col }}</td>
    </tr>
    <template v-for="bonbu in Array.from(this.dataMap.keys())" :key="bonbu">
        <tr class="table-row border-[1px]">
            <td class="table-cell border-[1px] w-[94px]" :rowspan="dataMap.get(bonbu).length+1">{{ bonbu }}</td>
        </tr>
        <tr class="table-row border-[1px] h-[4.7rem]" v-for="buseo in dataMap.get(bonbu)" :key="buseo">
            <td class="table-cell border-[1px] w-[94px]">{{ buseo.BUSEOMYEONG_NM }}</td>
            <td class="table-cell border-[1px]" v-for="i in 12" :key="i">{{ Math.round(buseo[`RESULT${i}`]*100)/100 }}%</td>
            <td>{{ getAverage(buseo) }}%</td>
        </tr>
        <tr class="table-row border-[1px] h-[4.7rem]">
            <td class="table-cell border-[1px]" colspan="2">소계</td>
            <td class="table-cell border-[1px] w-[110px]" v-for="i in 12" :key="i">{{ Math.round(dataMap.get(bonbu).reduce((accumulator, currentValue) => {return !isNaN(currentValue[`RESULT${i}`]) ? accumulator + currentValue[`RESULT${i}`] : accumulator}, 0)*100)/100 }}%</td>
            <td>{{ getAverageBonbu(bonbu) }}%</td>
        </tr>
    </template>
    <tr class="table-row border-[1px] h-[4.7rem]">
        <td class="table-cell border-[1px]" colspan="2">합계</td>
        <td class="table-cell border-[1px]" v-for="i in 12" :key="i">{{ getTotalMonth(i) }}%</td>
        <td>{{ getTotalAverage() }}%</td>
    </tr>
  </table>
</template>

<script>
import LogHelper from '../Helpers/LogHelper';
import RestHelper from '../Helpers/RestHelper';

export default {
    props:{
        year : Number
    },
    computed:{
        tableCols()
        {
            const ret = []
            for(let i=1;i<13;i++)ret.push(`${i}월`);
            ret.push('평균')
            return ret;
        }
    },
    data(){
        return{
            datasource : [],
            dataMap : new Map(),
        }
    },
    watch:{
        year()
        {
            this.getDatasource();
        }
    },
    async created(){
        await this.getDatasource();
    },
    methods:{
        async getDatasource()
        {
            const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','UR',[{ INWON_IPRYEOK_YEAR_CN : this.year }]);
            LogHelper.logWrite('UR현황 데이터 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                this.datasource = [...res._data]
                this.dataMap = new Map();
                for(const data of this.datasource)
                {
                    const key = data.BONBUMYEONG_NM;
                    if(!this.dataMap.has(key))
                    {
                        this.dataMap.set(key,[]);
                    }
                    this.dataMap.get(key).push(data);
                }
            }
        },
        getAverage(buseo)
        {
            let ret = 0;
            for(let i = 1; i<13; i++)
            {
                const value = Number(buseo[`RESULT${i}`]);
                if(!isNaN(value))
                {
                    ret+=value;
                }
            }
            return Math.round((ret/12)*100)/100;
        },
        getAverageBonbu(bonbu)
        {
            let ret = 0;
            const buseoList = this.dataMap.get(bonbu);
            for(const o of buseoList)
            {
                ret += this.getAverage(o);
            }
            return Math.round((ret)*100)/100;
        },
        getTotalMonth(month)
        {
            let ret = 0;
            for(const val of this.datasource.map(x=>x[`RESULT${month}`]))
            {
                ret += val;
            }
            return  Math.round(ret*100)/100;
        },
        getTotalAverage()
        {
            let ret = 0;
            for(let i=1; i<13; i++)
            {
                ret += this.getTotalMonth(i);
            }
            return Math.round(ret/12*100)/100;
        }
    }
}
</script>

<style>

</style>