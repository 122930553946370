<template>
    <OutputMonthPopup :year="year" :month="Number(month)"  ref="onClickShowPopup" @onClickShowPopup = "onClickShowPopup" v-if="isShow" />
    <OutputYearPopup :year="year" :month="Number(month)"  ref="onClickShowYear" @onClickShowYear = "onClickShowYear" v-if="isShowYear" />
    <div class="w-full h-full bg-white font-Pretendard px-[1rem] py-[1rem] overflow-x-auto overflow-y-hidden">
    <div class="border-b-2 mr-6">
        <input v-model="date" class="border-[1px] rounded-lg w-[14rem] h-[3.7rem] mb-[1rem] p-[0.4rem]" type="month"/>
    </div>
    <div class="flex flex-col h-[calc(100%-4.5rem)] pt-[1rem] overflow-y-auto pr-4">
        <div class="mt-[1.6rem] grid grid-cols-12 grid-flow-row-dense auto-rows-auto gap-[1.6rem] justify-between w-[calc(100%-1.6rem)] mx-[0.6rem]">
            <OutputBord class="w-[100%] max-h-[30rem] col-span-4 h-full" :year="year" :month="Number(month.replace(/[^0-9]/g,''))"/>
            <!-- <OutputMonthChart :year="year" :month="month" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="월간 실적" /> -->
            <OutputMonthChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="월간 실적" />
            <OutputYearChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="연간 실적" />
            <OutputBonbuMChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="본부별 월간 수주 실적" @isShow="onClickShowPopup"/>
            <OutputBonbuSugeumChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-8 h-full" title="본부별 월간 수금 실적" />
            <OutputBonbuYChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="본부별 연간 수주 실적" @isShowYear="onClickShowYear"/>
            <OutputBonbuYearSugeumChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-8 h-full" title="본부별 연간 수금 실적" />
            <OutputYearLineChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-6 h-full" title="최근 5년 추이" />
            <OutputIndangChart :year="year" :month="Number(month)" class="w-[100%]  max-h-[30rem] col-span-6 h-full" title="최근 5년 인당 추이" />
            <!-- <MAECHULSGChart :year="year" :month="Number(month.replace(/[^0-9]/g,''))" class="w-[100%]  max-h-[30rem] col-span-4 h-full" title="" /> -->
        </div>
    </div>
</div>
</template>

<script>
import OutputBord from './Charts/Dashboard/OutputBord'
import moment from 'moment';
import OutputMonthChart from './Charts/Dashboard/OutputMonthChart' // 월간 실적
import OutputYearChart from './Charts/Dashboard/OutputYearChart' // 연간 실적
import OutputBonbuMChart from './Charts/Dashboard/OutPutBonbu_M_Chart.vue' // 본부별 월간 수주 실적
import OutputBonbuSugeumChart from './Charts/Dashboard/OutputBonbu_Sugeum_Chart.vue' // 본부별 월간 수금실적
import OutputBonbuYChart from './Charts/Dashboard/OutPutBonbu_Y_Chart.vue' // 본부별 연간 수주 실적
import OutputBonbuYearSugeumChart from './Charts/Dashboard/OutputBonbu_Year_Sugeum_Chart.vue' // 본부별 월간 수금실적
import OutputYearLineChart from './Charts/Dashboard/OutputYearLineChart' // 최근 5년 추이
import OutputIndangChart from './Charts/Dashboard/OutputYearIndangChart.vue' // 최근 5년 인당 추이
import OutputMonthPopup from './Popup/Month_Pop.vue' // 월간실적 팝업
import OutputYearPopup from './Popup/Year_Pop.vue' // 연간간실적 팝업
//import MAECHULSGChart from './Charts/MAECHUL_SG_Chart.vue';
export default {
    components :{
        OutputBord,
        OutputMonthChart,
        OutputYearChart,
        OutputBonbuMChart,
        OutputBonbuSugeumChart,
        OutputYearLineChart,
        OutputIndangChart,
        OutputBonbuYChart,
        OutputBonbuYearSugeumChart,
        OutputMonthPopup,
        OutputYearPopup
        //MAECHULSGChart
    },
    props: {
        // year: {
        //     type: Number,
        //     required: true
        // },
        // month: {
        //     type: Number,
        //     required: true
        // },
    },
    data(){
        return{
            date : moment().format('YYYY-MM'),
            month: moment().format('MM'),
            year: Number(moment().format('YYYY')),
            isShow:false,
            isShowYear:false,
        }
    },
    watch:{
        async date(newValue,oldValue)
        {
            this.year = Number( moment().format('YYYY'));
            this.month = moment(newValue).format('MM');
        }
    },
    async created()
    {
    },
    methods:{
        onClickShowPopup() {
            this.isShow = !this.isShow;
            this.$refs.onClickShowPopup;
        },
        onClickShowYear() {
            this.isShowYear = !this.isShowYear;
            this.$refs.onClickShowYear;
        },
    }
}
</script>

<style>

</style>