import { createWebHistory, createRouter } from 'vue-router'

import Main from './components/MainView.vue'
import Pms from './components/Views/Pms_View.vue'
import Login from './components/Views/Login.vue'

const routes = [
  { path: '/', component: Main },
  { path: '/pms', component: Pms },
  { path: '/login', component: Login },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
})

export default router