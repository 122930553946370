<template>
  <div>
    <RouterView/>
  </div>
  <!-- <MainView class="font-NotoSansKR"></MainView> -->
  <!-- <div class="grid grid-rows-[60px_1fr] items-center w-screen h-screen">
    <section class="flex w-full h-full items-center border-b-2 border-gray-400">
      <span class="text-2xl font-bold text-gray-700 tracking-tighter">PCM 경영관리 시스템</span>
    </section>
  </div> -->
</template>

<script>
import {RouterView } from 'vue-router';

export default {
  name: 'App',
  components: {
    RouterView 
  },
  data(){
    return {

    }
  },
  mounted(){
    setInterval(() => {
      if(this.$router.currentRoute.value.path == '/login')
      {
        return;
      }
      const id = window.localStorage.getItem('userId')
      if(id === null){
        this.$router.push('/login')
      }
    }, 1000);
  },
  methods :{
  }
}
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
  font-family: Pretendard, Avenir, Helvetica, Arial, sans-serif;
}
html{
  font-size: 62.5%;
}
#app {
  font-family: Pretendard, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
