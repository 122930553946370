<template>
  <div class="w-full h-full bg-white font-Pretendard px-[1rem] py-[1rem] overflow-x-auto overflow-y-hidden">
    <!-- <div class="w-full border-b-2">
        <Calendar v-model="year" view="year" dateFormat="yy" class="border-[1px] rounded-lg w-[14rem] h-[3.7rem] text-[1.4rem] mb-[1rem] p-[0.4rem]" />
    </div> -->
    <div class="flex flex-row items-center mb-[1rem]">
        <select class="w-[14rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
            <option v-for="y in yearList" :key="y">{{ y }}</option>
        </select>
    </div>
    <div class="flex flex-col h-[calc(100%-4.5rem)] pt-[1.6rem] overflow-y-auto pr-4">
        <div class="grid grid-cols-12 grid-rows-2 gap-[1.6rem] justify-between w-full h-full px-[0.6rem]">
            <UR_Chart class="col-span-6" :year="year" title="UR 현황"/>
            <MAECHUL_Chart class="col-span-6" :year="year" title="매출"/>
            <MM_Chart class="col-span-6" :year="year" title="MM"/>
            <Inwon_Chart class="col-span-6" :year="year" title="인원"/>
        </div>
    </div>
  </div>
</template>

<script>
import LogHelper from '../Helpers/LogHelper';
import RestHelper from '../Helpers/RestHelper'
import UR_Chart from './Charts/UR_Chart'
import MAECHUL_Chart from './Charts/MAECHUL_Chart'
import MM_Chart from './Charts/MM_Chart'
import Inwon_Chart from './Charts/Inwon_Chart'

export default {
    components :{
        UR_Chart,MAECHUL_Chart,MM_Chart,Inwon_Chart
    },
    data(){
        return{
            yearList : [],
            year : null,
            month : '1월'
        }
    },
    watch:{
        // async year()
        // {
        //     await this.getYears();
        // }
    },
    async created()
    {
        await this.getYears();
    },
    methods:{
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
            LogHelper.logWrite(`HOME 연도 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear()
                })
            }
        },
    }
}
</script>

<style>

</style>