<template>
    <div id="OutputBonbu_Sugeum_Chart" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold px-[1.3rem] mb-[0.6rem] w-full">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem]">본부별 연간 수금 실적</h1>
                <p class="text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold pl-2">(단위 : 억원)</p>
            </div>
            <!-- <p class="py-2 px-4 w-[9rem] text-center select-none cursor-pointer text-[1.2rem] border-[1px] border-[#DDDDDD] bg-white text-[#62686A] rounded-[2rem]">상세보기</p> -->
        </div>
        <div class="flex flex-row items-center text-[1.1rem] px-4 mb-[1rem]">
            <div class="bg-[#E2E2E2] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
            <span>목표</span>
            <div class="bg-[#5BA9E1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>수금</span>
        </div>
        <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" :plugins="[ChartJSPluginDatalabels]"/>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import RestHelper from '../../../Helpers/RestHelper';
import LogHelper from '../../../Helpers/LogHelper';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: "OutputBonbuYearSugeumChart",
    components: {
        Bar,
    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loaded: false,
            //data: [[], [], []], // data 배열 추가 및 초기화
            chartData: {
                labels: ['CM본부','PM본부', '전략사업본부', '엔지니어링본부'],
                datasets: [
                    {
                        label: '목표',
                        data: [],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    {
                        label: '수금',
                        data: [],
                        backgroundColor: '#5BA9E1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function (value, context) {
                            return (value / 100000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
                        }
                    },
                }
            },
        }
    },
    async mounted() {
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            this.data = [[], [], []];
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('본부별 연간 수금실적 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD == '1') {
                    const data = [...res._data06];
                    this.loaded = true;
                    this.updateChart(data); 
                }
            } catch (error) {
                console.error("본부별 연간 수금실적 데이터 가져오기 실패:", error);
            };
            // 차트 유동적으로 조정~..
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                this.chartOptions = { ...tempOption };
            });
        },
        getNumber(str) {
            let unit = 10;
            return Math.round(Number(str) * unit) / unit;
        },
        updateChart(data) {
            this.chartData.datasets.forEach(dataset => {
                dataset.data = []; // 데이터 초기화
            });
            data.forEach(row => {
                this.chartData.datasets[0].data.push(this.getNumber(row.MOKPYO_AM));
                this.chartData.datasets[1].data.push(this.getNumber(row.WON_AM));
            });

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.barChart && this.$refs.barChart.chart) {
                    this.$refs.barChart.chart.update();
                } 
            });
        }
    },
};
</script>

<style scoped>
#OutputBonbu_Sugeum_Chart canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 1080px !important;
    max-height: 200px !important;
}
</style>
