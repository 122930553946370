<template>
  <div>
    <DxToolbar id="toolbar" class="bg-[#ffffff] border-b-[2px] flex items-center flex-row">
        <DxItem
            location="before"
            widget="dxButton"
            :options = "{icon: 'menu',id : 'test', onClick: () => { this.openState = !this.openState; }}"
        />
        <DxItem location="before" locate-in-menu="never">
            <img src="../assets/logo.png">
            <div class="text-[#0249AC] text-[1.5rem] tracking-[0px] ml-[1.2rem] font-Pretendard font-medium">PCM 경영관리 시스템</div>
        </DxItem>
        <DxItem location="after" locate-in-menu="never">
            <div @click="toggle" class="pi pi-bell relative mr-[2rem] select-none cursor-pointer" style="font-size: 2rem">
                <Badge v-if="alarmList.filter(x=>x.ALARM_VIEW_FG == 'Y').length != 0" :value="alarmList.filter(x=>x.ALARM_VIEW_FG == 'Y').length" class="absolute top-[-0.6rem] right-[-0.6rem]"/>
            </div>
            <img class="border-2 w-[3.4rem] h-[3.4rem] rounded-[2rem] mr-[1rem] object-scale-down" :src="userImg" onerror="this.onerror = null; this.src='./icon/user.png'" />
            <p class="mr-[2rem] text-[1.4rem]">{{userId}}</p>
            <input @click="onClickLogout" type="button" value="로그아웃" class="bg-[#0249AC] text-[#ffffff] px-[1.5rem] py-[0.5rem] rounded-[0.5rem] cursor-pointer">
            <OverlayPanel ref="op" appendTo="body" class="custom-position">
                <div class="flex flex-col gap-[1rem] w-[40rem] h-[20rem] p-[1rem] m-[1rem] overflow-y-auto">
                    <div @click="onClickAlarm($event,item)" v-for="(item, index) in alarmList" :key="index" class="flex flex-col w-full border-b-2 select-none cursor-pointer hover:bg-slate-100">
                        <p class="text-[1.4rem] text-[#92959B]">{{ item.OPDATE }}</p>
                        <p :style="{color : `${item.ALARM_VIEW_FG == 'Y' ? '#2B3133' : '#92959B'}`}" class="text-[1.3rem] font-semibold mb-[0.5rem]">
                            {{ item.ALARM_MEMO_NM }}
                        </p>
                    </div>
                </div>
            </OverlayPanel>
        </DxItem>
    </DxToolbar>
    <DxDrawer 
      :opened="openState"
      height="calc(100vh - 56px)"
      :close-on-outside-click="false"
      template="listMenu">
      <template #listMenu>
        <div class="w-[18rem] h-full border-r-2 box-border">
            <NavigationList ref="navList" @onChangeItem="onChangeItem"/>
        </div>
      </template>
      <div id="content" class="w-full h-full bg-white">
        <Contents ref="contents" :onChangeNav="onChangeNav"/>
      </div>
    </DxDrawer>
    <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMsg"
      v-model:type="toastType"
      :display-time="600"
    >
    <DxPosition my="top" at="top" offset="0 50"/>
    </DxToast>
  </div>
</template>

<script>
import DxDrawer from 'devextreme-vue/drawer';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import NavigationList from './NavigationList.vue';
import Contents from './Contents.vue'
import { DxToast, DxPosition } from 'devextreme-vue/toast';
import OverlayPanel from 'primevue/overlaypanel';
import RestHelper from './Helpers/RestHelper'
import Badge from 'primevue/badge';
import axios from 'axios'

export default {
    components : {
        DxDrawer,
        DxToolbar,
        DxItem,Badge,
        NavigationList,OverlayPanel,
        Contents,DxToast, DxPosition
    },
    computed:{
        contents(){
            return this.$refs.contents;
        },
        naviList()
        {
            return this.$refs.navList;
        }
    },
    watch :{
        openState(){
            // console.log(this.openState)
        }
    },
    beforeMount()
    {
        window.removeEventListener('beforeunload', this.unLoadEvent);
        this.getAlarm();
    },
    async mounted(){
        window.addEventListener('beforeunload', this.unLoadEvent);
        const url = '/haeahn/api/UserInfo/userinfo'
        const formData = new FormData();
        formData.append('Emp_no', this.userKey);

        axios.post(url, formData,{timeout:500}).then((res)=>{
            this.userImg = res.data.resultPHOTO_URL;
        }).catch((err)=>{
            this.userImg = ''
        });

        setInterval(() => {
            this.getAlarm()
        }, 600000);
    },
    data(){
        return{
            openState: true,
            toolbarContent: [
                {
                    widget: 'dxButton',
                    location: 'before',
                    options: {
                        icon: 'menu',
                        onClick: () => { this.openState = !this.openState; },
                    },
                },
            ],
            lastItem : null,
            toastVisible : false,
            toastMsg : "",
            toastType : "success", //'success' : 'error'
            userId : window.localStorage.getItem('userId'),
            userKey : window.localStorage.getItem('userKey'),
            alarmList : [],
            userImg : '',
        }
    },
    methods :{
        async getAlarm()
        {
            const res = await RestHelper.RestSend('USP_ALARM','LIST',[{
                EMP_NO : this.userKey
            }])
            if(res._result[0].RESULTCD == '1')
            {
                this.alarmList = [...res._data];
            }
        },
        async onClickAlarm(e,item)
        {
            await RestHelper.RestSend('USP_ALARM','UPDATE',[{
                EMP_NO : '',
                ALARM_SQ : item.ALARM_SQ
            }])
            await this.getAlarm();
        },
        onClickLogout()
        {
            window.localStorage.removeItem('userId')
            window.localStorage.removeItem('userKey')
            this.$router.push('/login')
        },
        onChangeItem(itemName)
        {
            const temp = [...this.contents.items]
            if(temp.find(x=>x.id==itemName.id))
            {
                // this.contents.items = this.contents.items.filter(x=>x.id != itemName.id);
                // this.contents.items.push(itemName);
                this.contents.selectedItem = itemName;
                this.naviList.selItemName = itemName;
                return;
            }
            if(temp.length > 9)
            {
                // this.contents.selectedItem = this.lastItem;
                // this.naviList.selItemName = this.lastItem;
                this.toastMsg = "최대 10개까지 선택 가능합니다."
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            temp.push(itemName);
            this.contents.items = temp;
            this.contents.selectedItem = itemName;
            this.lastItem = itemName;
        },
        onChangeNav(itemName)
        {
            this.naviList.selItemName = itemName;
        },
        unLoadEvent: function (event) {
            if (this.isLeaveSite) return;

            event.preventDefault();
            event.returnValue = '';
        },
        toggle(event)
        {
            this.$refs.op.toggle(event);
        }
    },
    beforeRouteEnter()
    {
        const id = window.localStorage.getItem('userId')
        if(id === null){
            this.$router.push('/login')
        }
    },
}
</script>

<style>
#toolbar{
      padding: 0px 1rem;
      height: 56px;
      box-sizing: border-box;
  }
  #toolbar .dx-button
  {
      background: transparent;
      border-color: transparent;
  }
  #toolbar .dx-button .dx-icon-menu{
      font-size: 2.5rem;
      text-align: start;
  }
  #toolbar .dx-button .dx-button-content .dx-icon{
      color: #2B3133;
  }
  .panel-list .dx-list-item {
      color: #5a5a5a;
      background: transparent;
      border-block-color: #a1a1a1;
      font-size: 1.2rem;
      font-weight: bold;
  }
  .panel-list .dx-list-item:hover{
      color: #000000;
  }
  .panel-list .dx-list-item .dx-icon {
      color: #fff !important;
  }
  .dx-toolbar-before {
      display: flex !important;
      flex-direction:row !important;
      align-items: center !important;
  }
  .dx-toolbar-item-content {
      display:flex !important;
      align-items: center !important;
      margin-right: 32px;
  }
  .custom-position {
    top: 50px !important;
    left: calc(100vw - 450px) !important;
    }
    

/* .dx-scrollable-scroll{
    height: 2rem !important;
}
.dx-scrollable-scroll-content{
    height: 2rem !important;
}
.dx-scrollable-scrollbar{
    height: 2rem !important;
} */
</style>