import RestHelper from '../Helpers/RestHelper'
export default class DataHelper{
    static async InitSujuData()
    {
        const res = await RestHelper.RestSend('USP_SUJU','LIST',[{}]);
        const data = res._data;

        for(let o of data)
        {
            o.SUJU_WAL = this.SetDtData(o.SUJUIL_DT,true);
            o.GYEYAKIL_DT = this.SetDtData(o.GYEYAKIL_DT);
            o.SUJUIL_DT = this.SetDtData(o.SUJUIL_DT);
        }
        return data;
    }

    static async InitSujuCodeData()
    {
        const res = await RestHelper.GetOrderCode();
        const data = res._data;
        const ret = {};
        ret.BUNRYU = data.filter(x=>x.GRPCD == 'BUNRYU');
        ret.BUSEO = data.filter(x=>x.GRPCD == 'BUSEO');
        ret.EOPMU = data.filter(x=>x.GRPCD == 'EOPMU');
        ret.GITA = data.filter(x=>x.GRPCD == 'GITA');
        ret.SUJU_YUL = data.filter(x=>x.GRPCD == 'SUJU_HAKYUL'); //수주확율

        return ret;
    }

    static async AddSujuData(o)
    {
        const options = await this.InitSujuCodeData();
        const BUSEO_CD = options.BUSEO.find(x=>x.NAME == o.BUSEO_CD)?.CODE;
        const BUNRYU_CD = options.BUNRYU.find(x=>x.NAME == o.BUNRYU_CD)?.CODE;
        const EOPMU_CD = options.EOPMU.find(x=>x.NAME == o.EOPMU_CD)?.CODE;
        const GITA_CD = options.GITA.find(x=>x.NAME == o.GITA_CD)?.CODE;
        const SUJU_HAKYUL_CD = options.SUJU_YUL.find(x=>x.NAME == o.SUJU_HAKYUL_CD)?.CODE;
        
        o.BUSEO_CD = BUSEO_CD;
        o.BUNRYU_CD = BUNRYU_CD;
        o.EOPMU_CD = EOPMU_CD;
        o.GITA_CD = GITA_CD;
        o.SUJU_HAKYUL_CD = SUJU_HAKYUL_CD;
        o.GYEYAKIL_DT = o.GYEYAKIL_DT?.replace(/-/g,'');
        o.SUJUIL_DT = o.SUJUIL_DT?.replace(/-/g,'');
        o.BUSEO_CD = ""
        
        const res = await RestHelper.AddOrderData(o);
    }

    static async UpdateSujuData(o)
    {
        if(!o.SUJU_SQ) return null;
        const options = await this.InitSujuCodeData();
        const BUSEO_CD = options.BUSEO.find(x=>x.NAME == o.BUSEO_CD)?.CODE;
        const BUNRYU_CD = options.BUNRYU.find(x=>x.NAME == o.BUNRYU_CD)?.CODE;
        const EOPMU_CD = options.EOPMU.find(x=>x.NAME == o.EOPMU_CD)?.CODE;
        const GITA_CD = options.GITA.find(x=>x.NAME == o.GITA_CD)?.CODE;
        const SUJU_HAKYUL_CD = options.SUJU_YUL.find(x=>x.NAME == o.SUJU_HAKYUL_CD)?.CODE;
        
        o.BUSEO_CD = BUSEO_CD;
        o.BUNRYU_CD = BUNRYU_CD;
        o.EOPMU_CD = EOPMU_CD;
        o.GITA_CD = GITA_CD;
        o.SUJU_HAKYUL_CD = SUJU_HAKYUL_CD;
        o.GYEYAKIL_DT = o.GYEYAKIL_DT?.replace(/-/g,'');
        o.SUJUIL_DT = o.SUJUIL_DT?.replace(/-/g,'');
        
        await RestHelper.UpdateOrderData(o);
    }

    static async DeleteSujuData(o)
    {
        await RestHelper.DelOrderData(o.SUJU_SQ);
    }

    static SetDtData(data,isMonth = false)
    {
        if(!data)
        {
            return "";
        }
        data = data?.replace(/-/g,'').trim();
        if(data.length != 8)
        {
            return "";
        }
        if(isMonth)
        {
            return `${data.substr(0,4)}-${data.substr(4,2)}`
        }
        return `${data.substr(0,4)}-${data.substr(4,2)}-${data.substr(6,2)}`
    }

    static StringNullorEmpty(target){
        return target == null || target == "" || target == undefined
    }

    static getDate(y=0,m=0,d=0,useTime=true)
    {
        const date = new Date();
        const year = date.getFullYear() + y;
        const month = ("0" + (1 + date.getMonth() + m)).slice(-2);
        const day = ("0" + (date.getDate() + d)).slice(-2);
        if(useTime)
        {
            return `${year}-${month}-${day} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        }
        return `${year}-${month}-${day}`;
    }
}