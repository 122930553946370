export default class SugeumModel{
    constructor(year)
    {
        this.SUGEUM_YEAR_NM = year; 
        this.SANGTAE_NM = 0; 
        this.SUJU_SQ = 0;
        this.BONBU_NM - "";
        this.BIGO_MONTH_CN1 = '';
        this.SUGEUM_MONTH_NM = 0;  
        this.SUGEUM_MONTH1_CN = 0; 
        this.SUGEUM_MONTH2_CN = 0; 
        this.SUGEUM_MONTH3_CN = 0; 
        this.SUGEUM_MONTH4_CN = 0; 
        this.SUGEUM_MONTH5_CN = 0; 
        this.SUGEUM_MONTH6_CN = 0; 
        this.SUGEUM_MONTH7_CN = 0; 
        this.SUGEUM_MONTH8_CN = 0; 
        this.SUGEUM_MONTH9_CN = 0; 
        this.SUGEUM_MONTH10_CN = 0; 
        this.SUGEUM_MONTH11_CN = 0; 
        this.SUGEUM_MONTH12_CN = 0; 
        this.TODAY_SUGEUM_MONTH_CN_S0 = 0; 
        this.TODAY_SUGEUM_MONTH_CN_S1 = 0; 
        this.SUGEUM_WON_AM1 = 0; 
        this.SUGEUM_WON_AM2 = 0; 
        this.SUGEUM_DATE_DT1 = 0; 
        this.SUGEUM_DATE_DT2 = 0;
        this.SUGEUM_MONTH_CN_OBJ={
            SUGEUM_MONTH1_CN_S0 : 0,
            SUGEUM_MONTH1_CN_S1 : 0,

            SUGEUM_MONTH2_CN_S0 : 0,
            SUGEUM_MONTH2_CN_S1 : 0,

            SUGEUM_MONTH3_CN_S0 : 0,
            SUGEUM_MONTH3_CN_S1 : 0,

            SUGEUM_MONTH4_CN_S0 : 0,
            SUGEUM_MONTH4_CN_S1 : 0,

            SUGEUM_MONTH5_CN_S0 : 0,
            SUGEUM_MONTH5_CN_S1 : 0,

            SUGEUM_MONTH6_CN_S0 : 0,
            SUGEUM_MONTH6_CN_S1 : 0,

            SUGEUM_MONTH7_CN_S0 : 0,
            SUGEUM_MONTH7_CN_S1 : 0,

            SUGEUM_MONTH8_CN_S0 : 0,
            SUGEUM_MONTH8_CN_S1 : 0,

            SUGEUM_MONTH9_CN_S0 : 0,
            SUGEUM_MONTH9_CN_S1 : 0,

            SUGEUM_MONTH10_CN_S0 : 0,
            SUGEUM_MONTH10_CN_S1 : 0,

            SUGEUM_MONTH11_CN_S0 : 0,
            SUGEUM_MONTH11_CN_S1 : 0,

            SUGEUM_MONTH12_CN_S0 : 0,
            SUGEUM_MONTH12_CN_S1 : 0,
        }
    }
    setSUGEUM_MONTH_CN_OBJ(sangtae,target)
    {
        for(let i = 1; i<13; i++)
        {
            this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S${sangtae}`] = target[`SUGEUM_MONTH_CN${i}`];
        }
    }
    setMONTH_Data_0()
    {
        for(let i = 1; i<13; i++)
        {
            this[`SUGEUM_MONTH${i}_CN`] = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S0`] ?? 0;
        }   
    }
    setMONTH_Data_1()
    {
        for(let i = 1; i<13; i++)
        {
            this[`SUGEUM_MONTH${i}_CN`] = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S1`] ?? 0;
        }   
    }
    setMONTH_Data()
    {
        for(let i = 1; i<13; i++)
        {
            const s0 = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S0`];
            const s1 = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${i}_CN_S1`];
            const valueIf = s1 == 0 || s1 == null || s1 == undefined;
            this[`SUGEUM_MONTH${i}_CN`] = valueIf ? s0 : s1;
        }
    }
    getMonthData(month)
    {
        const s0 = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${month}_CN_S0`];
        const s1 = this.SUGEUM_MONTH_CN_OBJ[`SUGEUM_MONTH${month}_CN_S1`];
        if(isNaN(s1))
        {
            return s0;
        }
        else
        {
            return s1;
        }
    }
}