export default class BunbaeModel{
    constructor()
    {
        this.isOpen = true;
        this.BUNBAE_YEAR_NM = '';
        this.SANGTAE_NM = '0';
        this.SUJU_SQ = '';
        this.BUSEO_SQ = '';
        this.BONBU_NM = '';
        this.PJ_NM = '';
        this.PJCODE_ID = '';
        this.BUNRYU_CD = '';
        this.EOPMU_CD = '';
        this.GYEYAK_GEUMAEK_AM = '';
        this.GONGDONG = '';
        this.BUNBAE_MONTH_NM = '';
        this.BUNBAE_MONTH1_CN = 0;
        this.BUNBAE_MONTH2_CN = 0;
        this.BUNBAE_MONTH3_CN = 0;
        this.BUNBAE_MONTH4_CN = 0;
        this.BUNBAE_MONTH5_CN = 0;
        this.BUNBAE_MONTH6_CN = 0;
        this.BUNBAE_MONTH7_CN = 0;
        this.BUNBAE_MONTH8_CN = 0;
        this.BUNBAE_MONTH9_CN = 0;
        this.BUNBAE_MONTH10_CN = 0;
        this.BUNBAE_MONTH11_CN = 0;
        this.BUNBAE_MONTH12_CN = 0;
        this.BUNBAE_WON_AM1 = '';
        this.BUNBAE_WON_AM2 = '';
        this.BIGO = '';
        this.VIEWTYPE = '1';
        this.BUNBAE_MONTH_CN_OBJ = {
            BUNBAE_MONTH1_CN_S0 : 0,
            BUNBAE_MONTH1_CN_S1 : 0,
            BUNBAE_MONTH2_CN_S0 : 0,
            BUNBAE_MONTH2_CN_S1 : 0,
            BUNBAE_MONTH3_CN_S0 : 0,
            BUNBAE_MONTH3_CN_S1 : 0,
            BUNBAE_MONTH4_CN_S0 : 0,
            BUNBAE_MONTH4_CN_S1 : 0,
            BUNBAE_MONTH5_CN_S0 : 0,
            BUNBAE_MONTH5_CN_S1 : 0,
            BUNBAE_MONTH6_CN_S0 : 0,
            BUNBAE_MONTH6_CN_S1 : 0,
            BUNBAE_MONTH7_CN_S0 : 0,
            BUNBAE_MONTH7_CN_S1 : 0,
            BUNBAE_MONTH8_CN_S0 : 0,
            BUNBAE_MONTH8_CN_S1 : 0,
            BUNBAE_MONTH9_CN_S0 : 0,
            BUNBAE_MONTH9_CN_S1 : 0,
            BUNBAE_MONTH10_CN_S0 : 0,
            BUNBAE_MONTH10_CN_S1 : 0,
            BUNBAE_MONTH11_CN_S0 : 0,
            BUNBAE_MONTH11_CN_S1 : 0,
            BUNBAE_MONTH12_CN_S0 : 0,
            BUNBAE_MONTH12_CN_S1 : 0,
        };
    }

    setMonthData()
    {
        for(let i =1; i<13; i++)
        {
            const s0 = this.BUNBAE_MONTH_CN_OBJ[`BUNBAE_MONTH${i}_CN_S0`];
            const s1 = this.BUNBAE_MONTH_CN_OBJ[`BUNBAE_MONTH${i}_CN_S1`];
            this[`BUNBAE_MONTH${i}_CN`] = s1 == null ? s0 : s1;
        }
    }

    getColumStatus(month)
    {
        try
        {
            const s1 = this?.BUNBAE_MONTH_CN_OBJ[`BUNBAE_MONTH${month}_CN_S1`] ?? 0;
            if(s1 != 0)
            {
                return 'target';
            }
        }
        catch
        {
            return '';
        }
        return '';
    }

    getTotal()
    {
        let total = 0;
        for(let i =1; i<13; i++)
        {
            total += this[`BUNBAE_MONTH${i}_CN`]
        }
        return total;
    }

    getSugeum(month)
    {
        try{
            return this[`SUGEUM_MONTH_CN${month}`]?.split('_')[0] ?? 0
        }
        catch{
            return 0
        }
    }

    getSugeumStyle(month)
    {
        const styleType = this[`SUGEUM_MONTH_CN${month}`]?.toString()?.split('_')[1]
        if(styleType == '1')
        {
            return "target"
        }
        return ""
    }
}