<template>
    <div id="OutputYearLine_Chart" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR min-w-[38rem] max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold px-[1.3rem] mb-[0.6rem] w-full">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem]">최근 5년 추이</h1>
                <p class="text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold pl-2">(단위 : 억원)</p>
            </div>
        </div>
        <div class="flex flex-row items-center text-[1.1rem] px-4 mb-[1rem]">
            <div class="bg-[#E15B5B] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
            <span>수주</span>
            <div class="bg-[#5BA9E1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>수금</span>
            <div class="bg-[#999999] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>인원</span>
        </div>
        <Line :options="chartOptions" :data="chartData" ref="lineChart" height="200" v-if="loaded" :plugins="[ChartJSPluginDatalabels]"/>
    </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale } from 'chart.js';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import RestHelper from '../../../Helpers/RestHelper';
import LogHelper from '../../../Helpers/LogHelper';
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale);


export default {
    name: "OutputYearLineChart",
    components: {
        Line,
    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loaded: false,
            // data: [[], [], []], // data 배열 추가 및 초기화
            chartData: {
                labels: ['2020','2021', '2022', '2023', '2024'],
                datasets: [
                    {
                        label: '수주',
                        data: [],
                        backgroundColor: '#E15B5B',
                        borderColor:'#E15B5B',
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            formatter: function (value, context) {
                            return (value / 100000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
                        }
                        },
                    },
                    {
                        label: '수금',
                        data: [],
                        backgroundColor: '#5BA9E1',
                        borderColor:'#5BA9E1',
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            formatter: function (value, context) {
                            return (value / 100000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
                        }
                        },
                    },
                    {
                        label: '인원',
                        data: [],
                        backgroundColor: '#9999',
                        borderColor:'#9999',
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            formatter: function (value, context) {
                            return (value / 100000000).toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
                        }
                        },
                    }
                ]
            },
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: '#787878',
                        font: {
                            weight: 'bold'
                        }
                    }
                }
            },
        }
    },
    async mounted() {
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.line.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            this.data = [[], [], []];
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('최근 5년 추이 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD == '1') {
                    const data = [...res._data07];
                    this.loaded = true;
                    this.updateChart(data); 
                }
            } catch (error) {
                console.error("최근 5년 추이 데이터 가져오기 실패:", error);
            };
            // 차트 유동적으로 조정~..
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                this.chartOptions = { ...tempOption };
            });
        },
        getNumber(str) {
            let unit = 10;
            return Math.round(Number(str) * unit) / unit;
        },
        updateChart(data) {
            this.chartData.datasets.forEach(dataset => {
                dataset.data = []; // 데이터 초기화
            });
            data.forEach(row => {
                this.chartData.datasets[0].data.push(this.getNumber(row.SUJU));
                this.chartData.datasets[1].data.push(this.getNumber(row.SUGEUM));
                this.chartData.datasets[2].data.push(this.getNumber(row.INWON));
            });

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.lineChart && this.$refs.lineChart.chart) {
                    this.$refs.lineChart.chart.update();
                } 
            });
        }
    },
};
</script>

<style scoped>
#OutputYearLine_Chart canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 1080px !important;
    max-height: 200px !important;
}
</style>
