import axios from 'axios'

export default class RestHelper{
    static async RestSend(procName,opJob,parameters=[{}])
    {
        const data =  {
            "_header": {
                    "procName": procName,
                    "opJob": opJob, 
                    "opUser": window.localStorage.getItem('userKey'),  
                    "userIp": "0.0.0.0", 
                    "access_token": "04c0a39f-cd64-4390-8785-1959edb62a92"
                },
            "_data": {
                "parameters": parameters
            }
        }
        const res = await axios.post('/proc/callproc',data);
        return res.data;
    }
    static async GetProjectSearchList(searchTitle)
    {
        return await this.RestSend("USP_PJ_INDANGDANGA","SEARCH",[{"SEARCH_KEYWORD": searchTitle}]);
    }

    static async GetProjectData(projSq)
    {
        return await this.RestSend("USP_PJ_INDANGDANGA","LIST",[{"SEARCH_KEYWORD": "","PROJECT_SQ": projSq}]);
    }

    static async AddProjectData(dataSet)
    {
        return await this.RestSend("USP_PJ_INDANGDANGA","INSERT",dataSet);
    }

    static async GetOrder()
    {
        return await this.RestSend("USP_SUJU","LIST");
    }

    static async GetOrderCode()
    {
        return await this.RestSend("USP_SUJU","INIT");
    }

    static async AddOrderData(o)
    {
        return await this.RestSend("USP_SUJU","INSERT",[o]);
    }

    static async UpdateOrderData(o)
    {
        return await this.RestSend("USP_SUJU","UPDATE",[o]);
    }

    static async DelOrderData(index)
    {
        return await this.RestSend("USP_SUJU","DELETE",[{SUJU_SQ : index,}]);
    }
}