export default class OmModel{
    constructor()
    {
        this.SUJU_SQ = "";  // SUJU_SQ
        this.DAMDANG_NM = "";  // 담당자
        this.PJCODE_ID = "";  // PJ코드
        this.BUSEO_CD = ""; //수주부서
        this.BUSEO_SQ = ""; //수주부서
        this.BUNRYU_CD = ""; //수주분류
        this.EOPMU_CD = ""; //업무분류
        this.GITA_CD = ""; //기타분류
        this.PJ_NM = ""; //PJ명
        this.BALJU_NM = ""; //발주처
        this.GYEYAKIL_DT = ""; //계약일
        this.SUJUIL_DT = ""; //수주일
        this.SUJU_HAKYUL_CD = ""; //수주확률
        this.PCM_JIBUN_AM = ""; //수주지분(PCM)
        this.SEOLGYE_JIBUN_AM = ""; //수주지분(설계)
        this.BEABUN_YUL_RT = ""; //배분율
        this.GYEYAK_WON_AM = ""; //계약금액
        this.BEABUN_JEON_WON_AM = ""; //배분 전
        this.BEABUN_JEON_YUL_AM = ""; //배분 전(확률반영)
        this.BEABUN_HU_WON_AM = ""; //배분 후
        this.BEABUN_HU_YUL_AM = ""; //배분 후(확률반영)
    }
}