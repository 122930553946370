<template>
  <div class="w-full h-full px-[1rem] py-[1rem] bg-white overflow-auto" id="MOKPYO_View">
    <div class="flex flex-row justify-between items-center w-full mb-[1rem] min-w-[1710px]">
        <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">목표관리</p>
        <div class="flex flex-row justify-end">
            <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">
                저장
            </div>
        </div>
    </div>
    <!-- <div class="h-4"></div> -->
    <!-- 240325 수정-->
    <div class="flex flex-row">
        <select class="select-none w-96 h-[3.7rem] border-[1px] text-left pl-[1.2rem] rounded-lg border-[#C7C7C7" v-model="year">
            <option v-for="y in yearList" :key="y">{{ y }}</option>
        </select>
    </div>
    <div class="flex flex-row justify-end py-[1rem] min-w-[1710px] w-full">
        <div @click="historySidebar.open()" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer ">변경 조회</div> 
    </div>
    <div class="flex flex-row text-center min-w-[1710px] w-full h-[calc(100%-14rem)]">
        <div class="border-2 h-full w-[20%]">
            <DxTreeList @row-click="onClickBuseo" height="100%" :data-source="buseoList" key-expr="BUSEO_SQ" parent-id-expr="Head_ID">
                <TreeColumn caption="본부명" data-field="BUSEOMYEONG_NM" alignment="center" class="bg-[#fafafa]"/>
                <TreeSelection mode="single"/>
            </DxTreeList>
        </div>
        <div class="h-full w-[1%]"></div>
        <div class="border-2 h-full w-[79%]">
            <DxDataGrid :data-source="dataSource" class="h-full">
                <DxEditing mode="cell" :allow-updating="true" :allow-adding="false" :allow-deleting="false"></DxEditing>
                <DxColumn caption="목표월" :allowSorting="false" :allowFiltering="false" :allowEditing='false' data-field="MOKPYO_MONTH_CN" alignment="center" width="8rem" id="month"/>
                <DxColumn format="###,##0" :allowSorting="false" :allowFiltering="false" caption="수주목표금액" data-field="SUJU_MOKPYO_AM" alignment="center" v-if="isPCMshow" />
                <DxColumn format="###,##0" :allowSorting="false" :allowFiltering="false" caption="수금목표금액" data-field="SUGEUM_MOKPYO_AM" alignment="center" v-if="isPCMshow" />
                <DxColumn format="###,##0" :allowSorting="false" :allowFiltering="false" caption="영업이익목표" data-field="IIK_MOKPYO_AM" alignment="center"/>
                <DxColumn format="#0.00'%'" :allowSorting="false" :allowFiltering="false" caption="영업이익율목표" data-field="IIGYUL_MOKPYO_AM" alignment="center"/>
                <DxSummary class="p-[0]">
                    <DxTotalItem display-format="{0}" value-format="#,##0" :column="`SUJU_MOKPYO_AM`" summary-type="sum"/>
                    <DxTotalItem display-format="{0}" value-format="#,##0" :column="`SUGEUM_MOKPYO_AM`" summary-type="sum"/>
                    <DxTotalItem display-format="{0}" value-format="#,##0" :column="`IIK_MOKPYO_AM`" summary-type="sum"/>
                </DxSummary>
            </DxDataGrid>
        </div>
    </div>
    <HistorySidebar ref="historySidebar" menuName="C0080"/>
    <DxToast
      v-model:visible="toastVisible"
      v-model:message="toastMsg"
      v-model:type="toastType"
      :display-time="600"
    >
    <DxPosition my="top" at="top" offset="0 50"/>
    </DxToast>
  </div>
</template>
 
<script>
import {
DxDataGrid,
DxColumn,
DxEditing,
DxSummary,DxTotalItem
} from 'devextreme-vue/data-grid';
import { DxTreeList, DxSelection as TreeSelection, DxColumn as TreeColumn } from 'devextreme-vue/tree-list';
import RestHelper from '../Helpers/RestHelper';
import LogHelper from '../Helpers/LogHelper';
import HistorySidebar from '../Controls/HistorySidebar.vue';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
 
export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxTreeList,
        TreeColumn,
        TreeSelection,
        HistorySidebar,
        DxToast, DxPosition,
        DxSummary,DxTotalItem
    },
    props:{
        type : String,
    },
    computed:{
        dataGrid()
        {
            return this.$refs.dataGrid.instance;
        },
        historySidebar()
        {
            return this.$refs.historySidebar;
        }
    },
    watch:{
        year()
        {
            this.getBuseo();
        },
    },
    data(){
        return{
            yearList : [],
            year : null,
            buseoList : [],
            dataSource : [],
            isPCMshow : true,
            toastVisible : false,
            toastMsg : "",
            toastType : "success", //'success' : 'error'
        }
    },
    async mounted(){
        await this.getYears();
    },
    methods:{
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_SUGEUM','INIT',[{}]);
            LogHelper.logWrite('목표관리 연도 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {  
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear();
                })
            }
        },
        async getBuseo()
        {
            const today = new Date();
            const todayYear = today.getFullYear();
            let props = [{}]
            if(Number(this.year) < Number(todayYear))
            {
                props = [{
                    STARTYEAR : todayYear+'1230',
                    ENDYEAR : todayYear+'1231'
                }]
            }

            const res = await RestHelper.RestSend('USP_BUSEO','LIST',props);
            LogHelper.logWrite('목표관리 부서 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data;
                const indexToDelete = data.findIndex(o => o.BUSEOMYEONG_NM === '품질안전실');
                if (indexToDelete !== -1) {
                    data.splice(indexToDelete, 1);
                }   
                // 품질안전실 splice
                for(const o of data)
                {
                    o.Head_ID = o.DEPT_LEVEL == 4 ? 0 : data.find(x=>x.BUSEOMYEONG_NM === o.BONBUMYEONG_NM)?.BUSEO_SQ;
                }
                this.buseoList = [...data];
            }
        },
        async onClickBuseo(e) {
            try {
                // PCM 지원실 인 지 체크
                if (e.data.BUSEOMYEONG_NM === 'PCM지원실') {
                    this.isPCMshow = false; // PCM 지원실이면 false로 설정해서 hide 처리
                } else {
                    this.isPCMshow = true; // PCM 지원실이 아니면 true로 설정해서 show 처리
                }

                if (Number(e.data.DEPT_LEVEL)=== 4 && e.node.children.length > 0){
                    const cArr = [];
                    for(const child of e.node.children){
                        const cData = child.data;
                        const temp = await this.getList(cData.BUSEO_SQ);
                        cArr.push([...temp]);
                    }
                    this.dataSource = [];
                    for(let i = 0; i < 12; i++){
                        const target = {
                            BUSEO_SQ : -1,
                            MOKPYO_MONTH_CN: i+1,
                            SUJU_MOKPYO_AM: 0,
                            SUGEUM_MOKPYO_AM: 0,
                            IIK_MOKPYO_AM: 0,
                            IIGYUL_MOKPYO_AM: 0
                        }
                        for(const cData of cArr){
                            target.SUJU_MOKPYO_AM += cData[i].SUJU_MOKPYO_AM;
                            target.SUGEUM_MOKPYO_AM += cData[i].SUGEUM_MOKPYO_AM;
                            target.IIK_MOKPYO_AM += cData[i].IIK_MOKPYO_AM;
                            target.IIGYUL_MOKPYO_AM += cData[i].IIGYUL_MOKPYO_AM;
                        }
                        this.dataSource.push(target);
                    }
                    return;
                }

                const BUSEO_SQ = e.data.BUSEO_SQ;
                this.dataSource = await this.getList(BUSEO_SQ);
            } catch (error) {
                console.error('Error in onClickBuseo:', error);
            }
        },
        async getList(BUSEO_SQ) {
            const res = await RestHelper.RestSend('USP_MOKPYO', 'LIST', [{
                BUSEO_SQ: BUSEO_SQ,
                MOKPYO_YEAR_CN: this.year
            }]);
            
            LogHelper.logWrite('목표관리 리스트 요청', [res], this.logUse);

            if (res._result[0].RESULTCD === '1') {
                const data = res._data;
                const ret = [];

                for (let month = 1; month < 13; month++) {
                const target = data.find(x => x.MOKPYO_MONTH_CN === month);

                if (target) {
                    target.SUJU_MOKPYO_AM = Number(target.SUJU_MOKPYO_AM) ?? 0;
                    target.SUGEUM_MOKPYO_AM = Number(target.SUGEUM_MOKPYO_AM) ?? 0;
                    target.IIK_MOKPYO_AM = Number(target.IIK_MOKPYO_AM) ?? 0;
                    target.IIGYUL_MOKPYO_AM = Number(target.IIGYUL_MOKPYO_AM) ?? 0;
                    ret.push(target);
                } else {
                    ret.push({
                        MOKPYO_SQ: null,
                        BUSEO_SQ: BUSEO_SQ,
                        MOKPYO_YEAR_CN: this.year,
                        MOKPYO_MONTH_CN: month,
                        SUJU_MOKPYO_AM: 0,
                        SUGEUM_MOKPYO_AM: 0,
                        IIK_MOKPYO_AM: 0,
                        IIGYUL_MOKPYO_AM: 0
                        });
                    }
                }
                return ret;
            }
        },
        async onClickSave()
        {
            if(this.dataSource[0].BUSEO_SQ === -1)
            {
                this.toastMsg = "저장 할 수 없습니다."
                this.toastType = 'error'
                this.toastVisible = true;
                return;
            }
            const res = await RestHelper.RestSend('USP_MOKPYO','INSERT',this.dataSource);
            LogHelper.logWrite('목표관리 저장 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                this.toastMsg = "저장 완료"
                this.toastType = 'success'
                this.toastVisible = true;
            }
        }
    }
}
</script>
 
<style>
#MOKPYO_View .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
    border: 1px solid #e0e0e0;
    vertical-align: middle !important;
    font-size: 1.25rem;
    font-weight: 500;
}
#MOKPYO_View .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter::after,
#MOKPYO_View .dx-datagrid .dx-column-indicators .dx-sort.dx-header-filter::after {
    margin:0
}
#MOKPYO_View .dx-scrollable-content tr td,
#MOKPYO_View .dx-datagrid-rowsview tr td {
    height: 47px ;
    text-align: left;
    padding: 0 15px;
    vertical-align: middle;
    color:#111111;
}
#MOKPYO_View .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
    width: 100%;
    margin:0 75px;
    max-width: 100%;
    color: #818181;
    font-size: 1.3rem;
    height: 47px;
    background:#fafafa;
    border-right: 1px solid #e5e7eb;
}

#MOKPYO_View .dx-scrollable-content tr td,
#MOKPYO_View .dx-datagrid-rowsview tr td {
    height: 47px ;
    text-align: right;
    padding: 0 15px;
    vertical-align: middle;
    color:#111111;
    font-size: 1.25rem !important;
    border: 1px solid #d9d9d9;
    border-top: 0;
}
#MOKPYO_View .dx-datagrid-rowsview tr td:first-child {
    border-left: 0;
}
#MOKPYO_View .dx-datagrid-rowsview tr td:last-child {
    border-right: 0;
}
#MOKPYO_View .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
    padding: 0;
    text-align: center;
}
#MOKPYO_View .dx-widget input {
    text-align: right;
    font-weight: 500;
}
#MOKPYO_View .dx-texteditor.dx-editor-filled.dx-state-readonly,
#MOKPYO_View .dx-texteditor.dx-editor-filled{
    background: 0;
}
#MOKPYO_View .dx-datagrid .dx-datagrid-headers .dx-header-row > td:first-child {
    vertical-align: middle;
}
#MOKPYO_View .dx-texteditor.dx-editor-filled::after {
    border:0;
}
#MOKPYO_View .dx-treelist-text-content {
    text-align:left
}
#MOKPYO_View .dx-treelist .dx-treelist-table .dx-header-row > td {
    background:#fafafa;
}
#MOKPYO_View .dx-row .dx-header-row {
    background:#fafafa
}

#MOKPYO_View .dx-datagrid .dx-column-indicators {
    padding: 0;
}
#MOKPYO_View .dx-datagrid-rowsview {
    overflow:visible
}
/* #MOKPYO_View .dx-datagrid-rowsview .dx-datagrid-content:not(.dx-datagrid-content-fixed){
    height: 100%;
} */
#MOKPYO_View .dx-datagrid-rowsview .dx-row.dx-row-lines td:first-child {
    background:#fafafa
}
#MOKPYO_View .dx-datagrid-total-footer > .dx-datagrid-content {
    padding: 0;
}
#MOKPYO_View .dx-gridbase-container {
    height: 100%;
}
#MOKPYO_View .dx-datagrid-rowsview.dx-empty {
    height: max-content;
}
</style>