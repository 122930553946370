<template>
    <div id="MAECHUL_SG_ChartView" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR min-w-[38rem] max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold pb-[0.6rem] w-full h-[1.5rem]">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem]">매출 / 수금</h1>
                <p class="text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold pl-2">(단위 : 억원)</p>
            </div>
        </div>
        <div class="flex flex-row items-center text-[1.1rem] mb-[1rem] h-[2rem]">
            <div class="bg-[#5BA9E1] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]"></div>
            <span>수금</span>
            <div class="bg-[#1E6090] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>매출</span>
            <div class="bg-[#14B0A7] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>매출 - 수금</span>
        </div>
        <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels';
import RestHelper from '../../Helpers/RestHelper';
import LogHelper from '../../Helpers/LogHelper';
import sf from 'sf'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: "MAECHIL_SG_Chart",
    components: {
        Bar,
    },
    props: {
        year: Number,
    },
    data() {
        return {
            loaded: false,
            chartData: {
                labels: ['수금', '매출', '매출 - 수금'],
                datasets: [
                    {
                        label: '매출 테스트',
                        data: [0, 0, 0],
                        backgroundColor: ['#5BA9E1', '#1E6090', '#14B0A7'],
                        barPercentage: 0.6,
                        categoryPercentage: 0.7,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function (value) {
                            return sf("{0:#,###,##0.##}",Number(value));
                        },
                        color: 'black',
                        font: {
                            weight: 'bold'
                        }
                    },
                }
            },
        }
    },
    async mounted() {
        // 플러그인 등록
        ChartJS.register(ChartJSPluginDatalabels);
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        }
    },
    methods: {
        async getSugeum() {
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('수금 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD == '1') {
                    return [...res._data02];
                }
            } catch (error) {
                console.error("수금 데이터 요청 실패:", error);
            }
            return [];
        },
        async getMaechul() {
            try {
                const res = await RestHelper.RestSend('USP_MAECHUL', 'TOTAL', [{
                    KEYWORD: '',
                    PROJECT_SQ: '',
                    INWON_SQ: '',
                    MAECHUL_YEAR_CN: this.year
                }]);
                LogHelper.logWrite('매출 데이터 요청', [res], this.logUse);
                
                if (res._result[0].RESULTCD === '1') {
                    const maechulData = res._data.map(item => ({
                        ...item,
                        WOLGISEONG_TOTAL: Math.round(Number(item.WOLGISEONG_TOTAL) / 100000000 * 10) / 10 // 억원 단위로 변환
                    }));
                    return maechulData;
                } else {
                    console.error("매출 데이터 요청 실패:", res._result[0].RESULTMSG);
                }
            } catch (error) {
                console.error("매출 데이터 요청 실패:", error);
            }
            return [];
        },
        async get() {
            this.loaded = false;
            try {
                const [sugeumData, maechulData] = await Promise.all([
                    this.getSugeum(),
                    this.getMaechul()
                ]);
                this.updateChartData(sugeumData, maechulData);
                this.loaded = true;
            } catch (error) {
                console.error("데이터 요청 실패:", error);
            }
            // 차트 유동적으로 조정~..
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                this.chartOptions = { ...tempOption };
            });
        },
        getNumber(str) {
            let unit = 10;
            return Math.round(Number(str) * unit) / unit;
        },
        updateChartData(sugeumData, maechulData) {
            // 데이터 초기화
            this.chartData.datasets[0].data = [0, 0, 0];

            // 수금 데이터 갖고오기
            sugeumData.forEach(row => {
                const sugeumValue = this.getNumber(row.수금실적);
                this.chartData.datasets[0].data[0] += sugeumValue;
            });

            // 매출 데이터 갖고오기
            let totalMaechul = 0;
            maechulData.forEach(row => {
                const value = row.WOLGISEONG_TOTAL;
                totalMaechul += value;
            });
            this.chartData.datasets[0].data[1] = totalMaechul;

            // 매출 - 수금 데이터~..
            this.chartData.datasets[0].data[2] = Math.round((totalMaechul - this.chartData.datasets[0].data[0]) * 10) / 10;

            // 차트 업데이트
            this.$nextTick(() => {
                if (this.$refs.barChart && this.$refs.barChart.chart) {
                    this.$refs.barChart.chart.update();
                }
            });
        },
    },
};

</script>

<style scoped>
#MAECHUL_SG_ChartView canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 600px !important;
    max-height: 250px !important;
}
</style>
