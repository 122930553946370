<template>
    <div class="w-auto h-full bg-white p-[2rem]">
        <p class="text-3xl font-bold text-[#787878] tracking-tighter">부서 관리</p>
        <div class="h-4"></div>
        <div class="flex flex-row h-full">
            <div class="flex flex-col mr-8">
                <!-- <div class="flex flex-row items-center justify-end mb-2 text-lg">
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full" @click="onClickIntegrateData('BONBU')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/integrate.png" />
                        본부통합
                    </div>
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full" @click="onClickEditData('BONBU')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/edit.png" />
                        본부편집
                    </div>
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full" @click="onClickAddData('BONBU')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/add.png" />
                        본부추가
                    </div>
                    <div class="text-[#787878] cursor-pointer flex flex-row h-full" @click="onClickDelete('BONBU')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/delete.png" />
                        본부삭제
                    </div>
                </div> -->
                <div class="h-14 text-[1.5rem] font-bold leading-[4rem] text-center text-[#787878] bg-[#D9D9D9]">본부 정보</div>
                <DxDataGrid id="bonbuGrid" ref="bonbuGrid" class="h-[calc(100%-60px-3rem)]" :data-source="bonbuList" @selection-changed="onChangeBonbuRow($event)" key-expr="BUSEO_SQ" :show-borders="true">
                    <DxPaging :enabled="false"/>
                    <DxSelection mode="single"/>
                    <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                    <DxHeaderFilter :visible="false" />
                    <DxScrolling mode="'virtual"/> 
                    <DxLoadPanel :enabled="true"/>
                    
                    <DxColumn caption="본부명" width="70%" data-field="BUSEOMYEONG_NM"/>
                    <DxColumn caption="본부색" width="10%" cell-template="color-template" alignment="center"/>
                    <DxColumn caption="시작일" width="20%" data-field="BUSEO_SIJAK_DT" alignment="center"/>
                    <template #color-template="{ data: row }">
                        <select @change="onChangeBuseoColor(row)" class="border-2 w-full" :style="{background : `#${row.data.BUSEO_COLOR_NM}`}" v-model="row.data.BUSEO_COLOR_NM">
                            <option class="hover:bg-transparent"  v-for="b in bonbuColors" :key="b" :style="{background : `#${b}`}" :value="b">{{  }}</option>
                        </select>
                        <!-- <div class="w-[2rem] h-[2rem] rounded-md m-auto" :style="{background : `#${row.data.BUSEO_COLOR_NM}`}"></div> -->
                        <!-- <select :style="{background : `#${row.data.BUSEO_COLOR_NM}`}"  class="w-full h-full" v-model="row.data.BUSEO_COLOR_NM">
                            <option v-for="color in bonbuColors" :key="color" :value="color" :style="{background : `#${color}`,color : `#${color}`,fontSize:'2rem'}">{{  }}</option>
                        </select> -->
                    </template>
                    <!-- <DxColumn caption="마감일" width="auto" data-field="BUSEO_MAGAM_DT"/> -->
                </DxDataGrid>
            </div>
            <div class="flex flex-col">
                <!-- <div class="flex flex-row justify-end mb-2 text-lg">
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full" @click="onClickIntegrateData('BUSEO')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/integrate.png" />
                        부서통합
                    </div>
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full" @click="onClickEditData('BUSEO')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/edit.png" />
                        부서편집
                    </div>
                    <div class="text-[#787878] mr-4 cursor-pointer flex flex-row h-full"  @click="onClickAddData('BUSEO')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/add.png" />
                        부서추가
                    </div>
                    <div class="text-[#787878] cursor-pointer flex flex-row h-full" @click="onClickDelete('BUSEO')">
                        <img class="mx-2 h-[80%]" src="../../assets/icon/delete.png" />
                        부서삭제
                    </div>
                </div> -->
                <div class="h-14 text-[1.5rem] font-bold leading-[4rem] text-center text-[#787878] bg-[#D9D9D9]">부서 정보</div>
                <DxDataGrid id="buseoGrid" ref="buseoGrid" class="h-[calc(100%-60px-3rem)]" :data-source="buseoList" @selection-changed="onChangeBuseoRow($event)" key-expr="BUSEO_SQ" :show-borders="true">
                    <DxPaging :enabled="false"/>
                    <DxSelection mode="single"  />
                    <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                    <DxHeaderFilter :visible="false" />
                    <DxScrolling mode="'virtual"/> 
                    <DxLoadPanel :enabled="true"/>
                    
                    <!-- <DxColumn caption="본부명" width="100%" data-field="BONBUMYEONG_NM"/> -->
                    <DxColumn caption="부서명" width="80%" data-field="BUSEOMYEONG_NM"/>
                    <DxColumn caption="시작일" width="auto" data-field="BUSEO_SIJAK_DT"/>
                    <!-- <DxColumn caption="마감일" width="auto" data-field="BUSEO_MAGAM_DT"/> -->
                </DxDataGrid>
            </div>
        </div>
        <DxPopup :title="popupType === 'BONBU' ? '본부 추가' : '부서 추가'" :visible="addPopupVisible" :width="400" height="auto">
            <div class="flex flex-col justify-between w-full h-full">
                <div>
                    <div id="BonbuType" v-if="popupType === 'BONBU'">
                        <div class="flex flex-col">
                            <label for="bonbuAddName" class="mb-4">본부명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                        <div class="flex flex-col">
                            <label for="bonbuAddName" class="mb-4">본부색</label>
                            <select class="border-2" :style="{background : `#${formModel.BUSEO_COLOR_NM}`}" v-model="formModel.BUSEO_COLOR_NM">
                                <option v-for="b in bonbuColors" :key="b" :style="{background : `#${b}`}" :value="b">{{  }}</option>
                            </select>
                        </div>
                    </div>
                    <div id="BuseoType" v-if="popupType === 'BUSEO'">
                        <div class="flex flex-col">
                            <label class="mb-4">본부명</label>
                            <select class="border-2" v-model="formModel.BONBUMYEONG_NM">
                                <option v-for="b in bonbuList" :key="b">{{ b.BUSEOMYEONG_NM }}</option>
                            </select>
                        </div>
                        <div class="flex flex-col mt-10">
                            <label for="bonbuAddName" class="mb-4">부서명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                    </div>
                    <div class="flex flex-col mt-10">
                        <label for="bonbuAddName" class="mb-4">시작일</label>
                        <input id="bonbuAddName" v-model="formModel.BUSEO_SIJAK_DT" class="border-2" type="date"/>
                    </div>
                </div>
                <div class="flex flex-row items-end justify-end mt-20">
                    <div @click="()=>{this.addPopupVisible = false}" class="border-2 py-2.5 px-10 mr-4 hover:bg-slate-100 cursor-pointer">닫기</div>
                    <div @click="onClickAddSave" class="border-2 py-2.5 px-10 bg-[#0E4296] text-white hover:bg-blue-900 cursor-pointer">저장</div>
                </div>
            </div>
        </DxPopup>
        <DxPopup :title="popupType === 'BONBU' ? '본부 편집' : '부서 편집'" :visible="editPopupVisible" :width="400" height="auto">
            <div class="flex flex-col justify-between w-full h-full">
                <div>
                    <div id="BonbuType" v-if="popupType === 'BONBU'">
                        <div class="flex flex-col">
                            <label for="bonbuAddName" class="mb-4">본부명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                        <div class="flex flex-col">
                            <label for="bonbuAddName" class="mb-4">본부색</label>
                            <select class="border-2" :style="{background : `#${formModel.BUSEO_COLOR_NM}`}" v-model="formModel.BUSEO_COLOR_NM">
                                <option v-for="b in bonbuColors" :key="b" :style="{background : `#${b}`}" :value="b">{{  }}</option>
                            </select>
                        </div>
                    </div>
                    <div id="BuseoType" v-if="popupType === 'BUSEO'">
                        <div class="flex flex-col">
                            <label class="mb-4">본부명</label>
                            <select class="border-2" v-model="formModel.BONBUMYEONG_NM">
                                <option v-for="b in bonbuList" :key="b">{{ b.BUSEOMYEONG_NM }}</option>
                            </select>
                        </div>
                        <div class="flex flex-col mt-10">
                            <label for="bonbuAddName" class="mb-4">부서명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                    </div>
                    <div class="flex flex-col mt-10">
                        <label for="bonbuAddName" class="mb-4">시작일</label>
                        <input id="bonbuAddName" v-model="formModel.BUSEO_SIJAK_DT" class="border-2" type="date"/>
                    </div>
                    <div class="flex flex-col mt-10">
                        <label for="bonbuAddName" class="mb-4">마감일</label>
                        <input id="bonbuAddName" v-model="formModel.BUSEO_MAGAM_DT" class="border-2" type="date"/>
                    </div>
                </div>
                <div class="flex flex-row items-end justify-end mt-20">
                    <div @click="()=>{this.editPopupVisible = false}" class="border-2 py-2.5 px-10 mr-4 hover:bg-slate-100 cursor-pointer">닫기</div>
                    <div @click="onClickEditSave" class="border-2 py-2.5 px-10 bg-[#0E4296] text-white hover:bg-blue-900 cursor-pointer">저장</div>
                </div>
            </div>
        </DxPopup>
        <DxPopup :title="popupType === 'BONBU' ? '본부 통합' : '부서 통합'" :visible="integratePopupVisible" :width="600" height="auto">
            <div class="flex flex-col justify-between w-full h-full">
                <div>
                    <div id="BonbuType" v-if="popupType === 'BONBU'">
                        <div class="flex flex-col">
                            <label for="bonbuAddName" class="mb-4">본부명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                    </div>
                    <div id="BuseoType" v-if="popupType === 'BUSEO'">
                        <div class="flex flex-col">
                            <label class="mb-4">본부명</label>
                            <input type="text" v-model="selBonbu.BUSEOMYEONG_NM" class="border-2" readonly/>
                        </div>
                        <div class="flex flex-col mt-10">
                            <label for="bonbuAddName" class="mb-4">부서명</label>
                            <input id="bonbuAddName" v-model="formModel.BUSEOMYEONG_NM" class="border-2" type="text"/>
                        </div>
                    </div>
                    <div class="flex flex-col mt-10">
                        <label for="bonbuAddName" class="mb-4">시작일</label>
                        <input id="bonbuAddName" v-model="formModel.BUSEO_SIJAK_DT" class="border-2" type="date"/>
                    </div>
                </div>
                <DxDataGrid id="popupGrid" ref="popupGrid" class="my-8 h-[300px]" :data-source="integrateList" key-expr="BUSEO_SQ" :show-borders="true">
                    <DxPaging :enabled="false"/>
                    <DxSelection show-check-boxes-mode="always" mode="multiple"/>
                    <DxSearchPanel :visible="false" :width="240" placeholder="Search..."/>
                    <DxHeaderFilter :visible="false" />
                    <DxScrolling mode="'virtual"/> 
                    <DxLoadPanel :enabled="true"/>
                    
                    <DxColumn :caption="popupType === 'BONBU' ? '본부명' : '부서명'" width="80%" data-field="BUSEOMYEONG_NM"/>
                    <DxColumn caption="시작일" width="auto" data-field="BUSEO_SIJAK_DT"/>
                    <!-- <DxColumn caption="마감일" width="auto" data-field="BUSEO_MAGAM_DT"/> -->
                </DxDataGrid>
                <div class="flex flex-row items-end justify-end mt-20">
                    <div @click="()=>{this.integratePopupVisible = false}" class="border-2 py-2.5 px-10 mr-4 hover:bg-slate-100 cursor-pointer">닫기</div>
                    <div @click="onClickIntegrateSave" class="border-2 py-2.5 px-10 bg-[#0E4296] text-white hover:bg-blue-900 cursor-pointer">저장</div>
                </div>
            </div>
        </DxPopup>
    </div>
</template>
  
<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxLoadPanel
} from 'devextreme-vue/data-grid';
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';
import { DxPopup } from 'devextreme-vue/popup';
import DpModel from '../Models/DpModel'

export default {
    components :{
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
    },
    computed:{
        bonbuGrid()
        {
            return this.$refs.bonbuGrid.instance;
        },
        buseoGrid()
        {
            return this.$refs.buseoGrid.instance;
        },
        popupGrid()
        {
            return this.$refs.popupGrid.instance;
        },
        bonbuColors()
        {
            return ['4963EE','BF2AEE','EBD100','59D900','00C6BE'
                ,'E15B5B','639E41','DCA638','68B0DD','AA50E2',
            ];

            // return ['F7E39A','FECE79','F8A555','F4806F','F38093'
            //        ,'F390BC','E4B7D4','8A8BC3','94CAE4','9FD9DA'
            //        ,'97D1A9','CAE189'];
        }
    },
    data(){
        return{
            originList : [],
            bonbuList : [],
            buseoList : [],
            integrateList : [],
            addPopupVisible : false,
            editPopupVisible : false,
            integratePopupVisible : false,
            popupType : "BUSEO",
            formModel : new DpModel(),
            selBonbu : new DpModel(),
            selBuseo : null,
        }
    },
    async mounted(){
        await this.getAllDepartment();
        if(this.bonbuList.length > 0){
            this.bonbuGrid.selectRows([this.bonbuList[0].BUSEO_SQ],true);
        }
    },
    methods:{
        dataGridRefresh()
        {
            this.$nextTick(()=>{
                this.bonbuGrid.refresh();
                this.buseoGrid.refresh();
            })
        },
        async onChangeYear()
        {
            await this.getAllDepartment();
        },
        getToday(y = 0 ,m = 0 ,d = 0)
        {
            const date = new Date();
            const year = date.getFullYear() + y;
            const month = ("0" + (1 + date.getMonth() + m)).slice(-2);
            const day = ("0" + (date.getDate() + d)).slice(-2);

            return year + "-" + month + "-" + day;
        },
        async getAllDepartment()
        {
            const res = await RestHelper.RestSend("USP_BUSEO","LIST",[{
                STARTYEAR : '0',
                ENDYEAR : this.getToday(),
                USE_YN : 'Y'
            }]);
            if(res._result[0].RESULTCD == 1)
            {
                const data = [...res._data];
                for(const o of data)
                {
                    o.BUSEO_COLOR_NM = o.BUSEO_COLOR_NM ?? this.bonbuColors[0];
                }
                this.originList = [...data];
                for(const o of this.originList)
                {
                    o.BUSEO_SIJAK_DT = DataHelper.SetDtData(o.BUSEO_SIJAK_DT);
                    o.BUSEO_MAGAM_DT = DataHelper.SetDtData(o.BUSEO_MAGAM_DT);
                }
                this.bonbuList = data.filter(x=>x.DEPT_LEVEL == 4);
                if(this.selBonbu?.BUSEOMYEONG_NM)
                { 
                    this.buseoList = this.originList.filter(x=>x.BONBUMYEONG_NM == this.selBonbu.BUSEOMYEONG_NM);
                }
                this.dataGridRefresh();
            }
        },
        onChangeBonbuRow(e){
            const rowData = e.selectedRowsData[0];
            if(!rowData)
                return;
            this.selBonbu = {};
            for(const key of Object.keys(rowData))
            {
                this.selBonbu[key] = rowData[key];
            }
            this.buseoList = this.originList.filter(x=>x.BONBUMYEONG_NM == this.selBonbu.BUSEOMYEONG_NM);
            this.bonbuGrid.refresh();
            setTimeout(()=>{
                if(this.buseoList.length > 0)
                {
                    this.buseoGrid.selectRows([this.buseoList[0].BUSEO_SQ],true);
                }
            },100)
        },
        onChangeBuseoRow(e){
            const rowData = e.selectedRowsData[0];
            if(!rowData)
                return;
            this.selBuseo = new DpModel();
            for(const key of Object.keys(rowData))
            {
                this.selBuseo[key] = rowData[key];
            }
        },
        async onChangeBuseoColor(row)
        {
            const res = await RestHelper.RestSend('USP_BUSEO','UPDATE',[{
                'STARTYEAR' : '',
                'ENDYEAR' : '',
                'USE_YN' : '',
                'BUSEO_SQ' : this.selBonbu.BUSEO_SQ,
                'BUSEOMYEONG_NM' : '',
                'BONBUMYEONG_NM' : '',
                'BUSEO_SIJAK_DT' : '',
                'BUSEO_MAGAM_DT' : '',
                'BUSEO_COLOR_NM' : row.data.BUSEO_COLOR_NM,
            }])
        },
        async onClickEditSave(){
            if(this.popupType == 'BONBU')
            {
                if(DataHelper.StringNullorEmpty(this.formModel.BUSEOMYEONG_NM))
                {
                   alert('본부명을 입력해주세요');
                   return; 
                }
            }
            else
            {
                if(DataHelper.StringNullorEmpty(this.formModel.BONBUMYEONG_NM))
                {
                   alert('본부명을 입력해주세요');
                   return; 
                }
                if(DataHelper.StringNullorEmpty(this.formModel.BUSEOMYEONG_NM))
                {
                   alert('부서명을 입력해주세요');
                   return; 
                }
            }
            if(DataHelper.StringNullorEmpty(this.formModel.BUSEO_SIJAK_DT))
            {
                alert('시작일을 입력해주세요');
                return; 
            }
            const data = {...this.formModel};
            data.BUSEO_SIJAK_DT = data.BUSEO_SIJAK_DT?.replace(/-/g,'');
            data.BUSEO_MAGAM_DT = data.BUSEO_MAGAM_DT?.replace(/-/g,'');
            const res = await RestHelper.RestSend('USP_BUSEO','UPDATE',[data]);
            if (res._result[0].RESULTCD == 1) {
                await this.getAllDepartment();
                this.editPopupVisible = false;
                if(this.$viewMap.has("PersonView")){
                    this.$viewMap.get("PersonView").refresh();
                }
            }
            else
            {
                alert(res._result[0].DETAILMSG);
            }
        }
    }
}
</script>
  
<style>
#bonbuGrid td{
    border: 1px solid #e0e0e0!important;
    font-size: 13px !important;
    padding: 8px !important;
    height: 4.7rem !important;
    padding: 0 8px;
}

#buseoGrid td{
    border: 1px solid #e0e0e0!important;
    font-size: 13px !important;
    height: 4.7rem !important;
    padding: 0 8px;
}

#popupGrid td{
    border: 1px solid #e0e0e0!important;
    font-size: 11px !important;
}
</style>