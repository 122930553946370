<template>
    <Sidebar :visible="visible" class="w-[37rem]" position="right" :modal="false">
        <template #container>
            <div class="flex flex-row items-center justify-between px-[1.2rem] bg-[#006EDD] h-[3.7rem]">
                <p class="text-white text-[1.4rem] font-semibold leading-[3.9rem] h-full">변경 조회</p>
                <svg @click="close" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" class="w-[1.8rem] mb-[0.2rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="flex flex-col gap-[1rem] p-[2rem] bg-[#EDEFF2] h-full w-full overflow-y-auto">
                <Card v-for="(key, index) in groupArr" :key="index" class="p-[0rem] rounded-2xl border-[2px] shadow-md">
                    <template #title>
                        <div class="flex flex-row items-center justify-between px-[2rem] mt-[2rem]">
                            <span class="text-[#111111] text-[1.4rem] font-semibold">{{ cardMap.get(key)[0].OPUSER }}</span>
                            <span class="text-[#787878] text-[1.1rem] font-semibold">{{ cardMap.get(key)[0].OPDATE }}</span>
                        </div>
                    </template>
                    <template #content>
                        <div class="my-[1rem] px-[2rem]">
                            <span class="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.3rem] mr-[0.4rem]">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                </svg>
                                <span class="text-[0.9rem] h-full pt-[0.2rem]">편집됨</span>
                            </span>
                            <p class="text-[1.4rem] text-[#787878] font-bold mt-[0.4rem]">{{ cardMap.get(key)[0].TITLE }}</p>
                        </div>
                        <div class="flex flex-col gap-[1rem] w-full bg-[#F5F5F5] px-[2rem] py-[1rem]">
                            <div v-for="(card, index) in cardMap.get(key)" :key="index">
                                <p class="text-[1.2rem] text-[#3d3d3d] font-semibold">{{ card.SUB_TITLE }}</p>
                                <p class="text-[1rem] text-[#3d3d3d] mt-[0.4rem]">{{ card.TXT }}</p>
                            </div>
                        </div>
                    </template>
                </Card>  
            </div>
        </template>
    </Sidebar>
</template>

<script setup>
import Sidebar from 'primevue/sidebar';
import Card from 'primevue/card';
import { ref, defineProps, defineExpose } from 'vue';
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';

const visible = ref(false);
const cardMap = ref(new Map());
const groupArr = ref([]);

const props = defineProps({
    menuName: String
});

function close() {
    visible.value = true;
    visible.value = false;
}

async function open() {
    visible.value = false;
    visible.value = true;

    await get();
}

async function get() {
    const res = await RestHelper.RestSend('USP_HISTORY','INFO',[{MENU_NM : props.menuName}]);
    if(res._result[0].RESULTCD == '1')
    {
        const cardArr = [...res._data];
        groupArr.value = [...new Set(cardArr.map(x=>x.GROUP_KEY))]
        for(const key of groupArr.value)
        {
            cardMap.value.set(key, cardArr.filter(x=>x.GROUP_KEY == key));
        }
    }
}

defineExpose({
    open
})
</script>

<style scoped>


</style>