import clipboard from 'clipboardy';
export default class{ 
    static isSelectionStopped = true;
    static selectedRange = {}
    static isCtrlInput = false;
    static target = null;
    static keyExpr = "test";

    static foreachRange(selectedRange, func) {
        if (selectedRange.startRowIndex >= 0) {
            const minRowIndex = Math.min(
                selectedRange.startRowIndex,
                selectedRange.endRowIndex
            );
            const maxRowIndex = Math.max(
                selectedRange.startRowIndex,
                selectedRange.endRowIndex
            );
            const minColumnIndex = Math.min(
                selectedRange.startColumnIndex,
                selectedRange.endColumnIndex
            );
            const maxColumnIndex = Math.max(
                selectedRange.startColumnIndex,
                selectedRange.endColumnIndex
            );

            for (let rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
                for ( let columnIndex = minColumnIndex; columnIndex <= maxColumnIndex; columnIndex++)
                {
                    func(rowIndex, columnIndex);
                }
            }
        }
    }
    static showSelection(e) {
        const selectedCells = document.querySelectorAll('.cell-selected');

        if (selectedCells) {
            for (const o of selectedCells) {
                o.classList.remove('cell-selected');
            }
        }
        this.target = e.component;
        this.foreachRange(this.selectedRange,(rowIndex, columnIndex)=>{
            const cell = this.target.getCellElement(rowIndex,columnIndex);
            cell.classList.add('cell-selected');
            const test = (e)=>{this.onCellKeyDown(e)}
            cell.removeEventListener('keydown',test);
            cell.addEventListener('keydown',test);
        })
    }
    static onCellClick(e)
    {
        if (e.event && e.event.ctrlKey === false && e.event.shiftKey === true)
        {
            this.selectedRange.endRowIndex = e.rowIndex;
            this.selectedRange.endColumnIndex = e.columnIndex;
        }
        else
        {
            this.selectedRange = {};
            if (this.selectedRange.startRowIndex == undefined) {
                this.selectedRange.startRowIndex = e.rowIndex;
                this.selectedRange.endRowIndex = e.rowIndex;
            }
    
            if (this.selectedRange.startColumnIndex == undefined) {
                this.selectedRange.startColumnIndex = e.columnIndex;
                this.selectedRange.endColumnIndex = e.columnIndex;
            }
        }
        
        this.showSelection(e);
    }
    static onCellHoverChanged(e)
    {
        const event = e.event;
        if (event.buttons === 1) {
            if (this.isSelectionStopped) {
                this.isSelectionStopped = false;
                this.selectedRange = {};
            }
    
            if (this.selectedRange.startRowIndex == undefined) {
                this.selectedRange.startRowIndex = e.rowIndex;
            }
    
            if (this.selectedRange.startColumnIndex == undefined) {
                this.selectedRange.startColumnIndex = e.columnIndex;
            }
    
            this.selectedRange.endRowIndex = e.rowIndex;
            this.selectedRange.endColumnIndex = e.columnIndex;

            this.showSelection(e);
        } else {
            this.isSelectionStopped = true;
        }
    }
    static async onCellKeyDown(e)
    {
        if(e.keyCode == 17)
        {
            this.isCtrlInput = true;
        }
        else
        {
            if(this.isCtrlInput)
            {
                if(e.keyCode == 67)
                {
                    const selectedCell = document.getElementsByClassName('cell-selected');
                    let copyString = '';
                    let startRow = selectedCell[0].parentNode.rowIndex;
                    for(const cell of selectedCell)
                    {
                        const cellRow = cell.parentNode.rowIndex;
                        if(startRow != cellRow)
                        {
                            startRow = cellRow;
                            copyString += '\r\n'
                        }
                        else if(copyString != '')
                        {
                            copyString += '\t'
                        }
                        copyString += cell.innerText;
                    }
                    await clipboard.write(copyString);
                }
                if(e.keyCode == 86)
                {
                    const dataSource = this.target.getDataSource()._items;
                    const text = await clipboard.read();
                    const selectedCell = document.getElementsByClassName('cell-selected');
                    let startRow = selectedCell[0]?.parentNode?.rowIndex;
                    let startIndex = selectedCell[0]?.cellIndex;
                    
                    const textRows = text.split('\r\n').filter(x=>x!='');
                    if(textRows.length<1)return;
                    const textcol = textRows[0].split('\t');
                    if(textcol.length == 1 && textRows.length == 1)
                    {
                        for(let cell of selectedCell)
                        {
                            const target = cell.childNodes[0].classList?.contains('target');
                            const target2 = cell.childNodes[0].classList?.contains('target2');
                            const colOption = this.target.columnOption(cell.cellIndex);
                            if(!colOption.allowEditing || target || target2) continue;
                            const field = this.target.getVisibleColumns()[startIndex].dataField;
                            const rowId = dataSource.findIndex(x=>x[this.keyExpr] == this.target.getKeyByRowIndex(cell.parentNode.rowIndex));
                            dataSource[rowId][field] = textRows[0].split('\t')[0];
                            this.target.refresh();
                            setTimeout(() => {
                                const newCell = this.target.getCellElement(startRow,startIndex);
                                newCell.classList.add('cell-selected');
                            },100);
                        }
                        this.target.refresh();
                    }
                    else if(textcol.length > 1 || textRows.length > 1)
                    {
                        const selRanges = [];
                        for(let textRow of textRows)
                        {
                            if(textRow == '')
                                continue;
                            const textSplit = textRow.split('\t');
                            let index = startIndex;
                            for(let t of textSplit)
                            {
                                const cell = this.target.getCellElement(startRow,index);
                                if(!cell) continue;
                                const colOption = this.target.columnOption(cell.cellIndex);
                                if(!colOption.allowEditing) { selRanges.push({row : startRow,col:index+1}); index+=1; continue;}
                                if(cell.getElementsByClassName('target').length>0) { selRanges.push({row : startRow,col:index+1}); index+=1; continue;}
                                if(cell.getElementsByClassName('target2').length>0) { selRanges.push({row : startRow,col:index+1}); index+=1; continue;}

                                const field = this.target.getVisibleColumns()[index].dataField;
                                const rowId = dataSource.findIndex(x=>x[this.keyExpr] == this.target.getKeyByRowIndex(cell.parentNode.rowIndex));
                                dataSource[rowId][field] = t;
                                selRanges.push({row : startRow,col:index});
                                index++;
                            }
                            startRow++;
                        }
                        this.target.refresh();
                        setTimeout(() => {
                            for(const o of selRanges)
                            {
                                const newCell = this.target.getCellElement(o.row,o.col);
                                newCell.classList.add('cell-selected');
                            }
                        },100);
                    }
                }
            }
        }
    }
}