<template>
  <div class="w-full h-full bg-white p-[1rem] font-NotoSansKR">
    <div class="flex flex-row items-center justify-between mb-[1rem]">
        <select class="w-[14rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
            <option v-for="y in yearList" :key="y">{{ y }}</option>
        </select>
        <div @click="onClickExport" class="text-lg w-36 h-12 leading-[2.6rem] mx-2 text-center bg-white border-gray-400 border-[1px] rounded-lg hover:bg-slate-100 cursor-pointer">엑셀 다운로드</div>
    </div>
    <div class="w-full border-b-2 mr-6"></div>
    <div class="flex flex-col h-[calc(100%-2rem)] mt-[1rem] overflow-y-auto">
        <div class="w-full h-full border-2 mb-[4rem] mt-[1.6rem]">
            <Report_Bord ref="Report" class="w-full h-full border-2" :year="year"/>
        </div>
    </div>
  </div>
</template>

<script>
import LogHelper from '../Helpers/LogHelper';
import RestHelper from '../Helpers/RestHelper'
import Report_Bord from './Charts/Report_Bord'
export default {
    components :{
        Report_Bord
    },
    data(){
        return{
            yearList : [],
            year : null,
            month : '1월'
        }
    },
    async created()
    {
        await this.getYears();
    },
    methods:{
        async getYears()
        {
            const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
            LogHelper.logWrite(`HOME 연도 요청`,[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const data = res._data[0];
                for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
                {   
                    this.yearList.push(i);
                }
                this.$nextTick(()=>{
                    const now = new Date();
                    this.year = now.getFullYear()
                })
            }
        },
        onClickExport()
        {
            this.$refs.Report.exportToExcel();
        }
    }
}
</script>

<style>

</style>