<template>
    <div class="w-full h-full px-[1rem] py-[2rem] bg-white font-NotoSansKR" id="DetailUR_View">
        <SelectButton v-model="tabType" :options="[{ value: 'UR/MM 현황' },{ value: '본부별 UR' },{ value: '개인별 UR' }]" class="flex text-center mb-[2rem] justify-around items-center w-[41.2rem] h-[4rem] bg-[#f8f8f8] rounded-lg p-[4px]" optionLabel="value" dataKey="value" aria-labelledby="custom">
            <template #option="slotProps">
                <p class="cursor-pointer select-none text-[1.1rem] rounded-lg w-[13.2rem] text-center py-[0.9rem] h-[3.2rem]]">{{ slotProps.option.value }}</p>
            </template>
        </SelectButton>
        <div class="flex flex-row items-center justify-between w-full min-w-[1700px]">
            <div class="flex flex-row items-center mb-[1rem] pl-[0rem] pb-0">
                <p class="mr-[1rem] text-[#92959B] text-[1.3rem]">조회연도</p>
                <select class="w-[12rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7]" v-model="year">
                    <option v-for="y in yearList" :key="y">{{ y }}</option>
                </select>
            </div>
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
        <div class="w-full border-b-2 min-w-[1700px]"></div>
        <div class="w-full mt-[2.6rem] flex flex-row justify-start items-start min-w-[1700px]">
            <div v-if="tabType.value === 'UR/MM 현황'"  class="flex flex-row items-center gap-[1.6rem] h-[39rem] overflow-x-auto overflow-y-hidden">
            <div class="w-[56rem] h-full p-[1rem] border-2 rounded-[1.4rem] shadow-lg font-NotoSansKR">
                <div class="flex flex-row gap-[1rem] items-center">
                    <div class="font-semibold text-[1.6rem] mb-[1rem] mt-[1rem]">현황</div>
                    <div class="font-semibold text-[1.2rem] text-[#ADAEB1] mb-[1rem] mt-[1rem]">(단위:억원)</div>
                </div>
                <div class="grid grid-cols-2 grid-rows-3 gap-[1.2rem] p-[1rem] h-[calc(100%-3rem)]">
                    <div class="flex flex-col items-center justify-center gap-[1rem] bg-[#D9E9FA] border-2 row-span-3 rounded-xl">
                        <p class="text-[1.6rem] text-[#62686A]">{{month}}월 UR</p>
                        <p class="text-[3.2rem] text-[#2B3133]">{{setUnitPercent(urBord[0])}}%</p>
                    </div>
                    <div class="flex flex-col items-center justify-center gap-[1rem] bg-[#E0E6ED] border-2 rounded-xl row-start-1 col-start-2">
                        <p class="text-[1.6rem] text-[#62686A]">24년 평균 UR</p>
                        <p class="text-[2rem] text-[#2B3133]">{{setUnitPercent(urBord[1])}}%</p>
                    </div>
                    <div class="flex flex-col items-center justify-center gap-[1rem] bg-[#E0E6ED] border-2 rounded-xl row-start-2 col-start-2">
                        <p class="text-[1.6rem] text-[#62686A]">확정월 UR</p>
                        <p class="text-[2rem] text-[#2B3133]">{{setUnitPercent(urBord[2])}}%</p>
                    </div>
                    <div class="flex flex-col items-center justify-center gap-[1rem] bg-[#E0E6ED] border-2 rounded-xl row-start-3 col-start-2">
                        <p class="text-[1.6rem] text-[#62686A]">향후 2개월 UR</p>
                        <p class="text-[2rem] text-[#2B3133]">{{setUnitPercent(urBord[3])}}%</p>
                    </div>
                </div>
            </div>
            <div class="w-[56rem] h-full">
                <UR_Chart class="h-full w-full p-[2rem]" title="UR" :year="year"/>
            </div>
            <div class="w-[56rem] h-full">
                <MM_Chart class="h-full w-full p-[2rem]" title="MM" :year="year"/>
            </div>
            </div>
        </div>

        <div v-if="tabType.value === '본부별 UR'" class="h-[calc(100%-19rem)] min-w-[1700px]">
            <DxTreeList height="100%" :data-source="bonbuUrArr" parent-id-expr="P_NO" key-expr="NO" class="border-2 ">
                <TreeColumn
                    data-field="CHILD_BBUSEO_NAME"
                    caption="부서"
                    cell-template="buseoCell"
                    class="border-2"
                    :width="200"
                    :show-borders="true"
                />
                <TreeColumn v-for="month in 12" :key="month"
                    :data-field="`MONTH${month}_AM`"
                    :caption="`${month}월`"
                    alignment="right"
                    format="###,###,###,##0"
                />
                <template #buseoCell="{data : rowInfo}">
                    <div class="flex flex-row items-center justify-start overflow-hidden w-full">
                        <div :style="{ background: '#'+rowInfo.data.CHILD_BBUSEO_COLOR }" :class="`w-[0.8rem] h-[1.4rem] rounded-lg mr-[0.4rem]`"></div>
                        <p class="overflow-hidden text-ellipsis">{{ rowInfo.data.CHILD_BBUSEO_NAME }}</p>
                    </div>
                </template>
            </DxTreeList>
            <div class="flex flex-row w-full h-[6rem] border-2">
                <div class="h-full w-[210px] border-r-[1px]"></div>
                <div v-for="month in 12" :key="month" class="flex items-center justify-center h-full w-[7.7%] border-r-[1px] text-center">{{ setUnit(bonbuUrMap[`MONTH${month}_AM`]) }}</div>
            </div>
        </div>
        <div v-if="tabType.value === '개인별 UR'" class="h-[calc(100%-14rem)]">
            <DxDataGrid id="PersonGrid"  ref="dataGrid" width="100%" height="100%" column-resizing-mode="widget"
                :allow-column-resizing="true" :allow-column-reordering="true"
                :data-source="personUrArr" key-expr="INWON_SQ" :show-borders="true" :show-column-lines="showColumnLines" class="overflow-auto">
                <DxPaging :enabled="false"/>
                <DxSelection mode="single" />
                <DxHeaderFilter :visible="true" />
                <DxScrolling mode="'virtual"/> 
                <DxLoadPanel :enabled="true"/>
                <DxFilterRow :visible="true"/>
                <DxFilterPanel :visible="false"/>
                <DxFilterBuilderPopup :visible="false" :position="filterBuilderPopupPosition"/>
                <template #first-cell-template="{ data: info }">
                    <div class="flex flex-row items-center overflow">
                        <div :style="{background : `#${info.data.BUSEO_COLOR_NM}`}" class="rounded-lg w-[5px] h-[16px] mr-[1rem]"></div>
                        <div id="textOverflow">{{ info.data.BONBUMYEONG_NM }}</div>
                    </div>
                </template>
                <template #sec-cell-template="{ data: info }">
                    <div class="flex flex-row items-center">
                        <div :style="{background : `#${info.data.BUSEO_COLOR_NM}`}" class="rounded-lg w-[5px] h-[16px] mr-[1rem]"></div>
                        <div id="textOverflow">{{ info.data.BUSEOMYEONG_NM }}</div>
                    </div>
                </template>
                <DxColumn caption="본부" data-type="string" data-field="BONBUMYEONG_NM" cell-template="first-cell-template" width="150px"/>
                <DxColumn cell-template="sec-cell-template" data-field="BUSEOMYEONG_NM" caption="실" data-type="string" width="120px"/>
                <DxColumn data-field="USER_NM" caption="이름"/>
                <DxColumn format="#0.#0'%'" data-field="UR" :allow-filtering="true" caption="현재기준 평균" width="auto"/>
                <template v-for="(item, index) in 12" :key="index">
                    <DxColumn format="#0.#0'%'" :allow-filtering="false" :data-field="`UR${index+1}`" :caption="`${index+1}월`" alignment="right"/>
                </template>
            </DxDataGrid>
        </div>
    </div>
</template>

<script setup>
import {
DxDataGrid,
DxPaging,
DxColumn,
DxHeaderFilter,
DxScrolling,
DxSelection,
DxLoadPanel,
DxFilterRow,
DxFilterPanel,
DxFilterBuilderPopup
} from 'devextreme-vue/data-grid';
import {
  DxTreeList, DxColumn as TreeColumn
} from 'devextreme-vue/tree-list';
import SelectButton from 'primevue/selectbutton';
import UR_Chart from './Charts/UR_Chart'
import MM_Chart from './Charts/MM_Chart'
import { ref, onMounted, watch } from 'vue';
import RestHelper from '../Helpers/RestHelper';
import DataHelper from '../Helpers/DataHelper';
import sf from 'sf';

const dataGrid = ref(null)
const tabType = ref({ value: 'UR/MM 현황' });
const yearList = ref([]);
const year = ref(2024);
const bonbuUrArr = ref([]);
const bonbuUrMap = ref(new Map());
const personUrArr = ref([]);
const showColumnLines = ref(true); //table td border show / hide~...
const urBord = ref([]);
const month = ref(new Date().getMonth() + 1);

const filterBuilderPopupPosition = {
  of: window,
  at: 'top',
  my: 'top',
  offset: { y: 10 },
};

watch(year,async ()=>{
    await getURBoard();
    await getBonbuUR();
    await getPersonUR();
})

watch(tabType,(n,o)=>{
    if(n == null)
    {
        tabType.value = o;
    }
})

init();

async function init()
{
    await getYear();
    await getURBoard();
    await getBonbuUR();
    await getPersonUR();
}

async function getURBoard()
{
    const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','DETAILUR',[{INWON_IPRYEOK_YEAR_CN : year.value}]);
    if(res._result[0].RESULTCD == '1')
    {
        urBord.value = [];
        urBord.value.push(res._data[0].UR1)
        urBord.value.push(res._data01[0].UR2)
        urBord.value.push(res._data02[0].UR3)
        urBord.value.push(res._data03[0].UR4)
    }
}

async function getYear()
{
    const res = await RestHelper.RestSend('USP_BUNBAE','INIT',[{}]);
    if(res._result[0].RESULTCD == '1')
    {
        const data = res._data[0];
        yearList.value = [];
        for(let i = data.YONGYEOK_SIJAK_YEAR; i<= data.YONGYEOK_MAGAM_YEAR; i++)
        {   
            yearList.value.push(i);
        }
        const now = new Date();
        year.value = now.getFullYear();
    }
}

async function getBonbuUR()
{
    const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','BUSEOUR',[{INWON_IPRYEOK_YEAR_CN : year.value}]);
    if(res._result[0].RESULTCD == 1)
    {
        bonbuUrArr.value = [];
        bonbuUrArr.value = [...res._data];

        for(const o of bonbuUrArr.value.filter(x=>DataHelper.StringNullorEmpty(x.CHILD_BBUSEO_NAME)))
        {
            o.CHILD_BBUSEO_NAME = o.PARENTS_BUSEO_NAME; 
            o.CHILD_BBUSEO_COLOR = o.PARENTS_BUSEO_COLOR;
            for(let i = 1; i<13; i++)
            {
                if(bonbuUrMap.value[`MONTH${i}_AM`] == undefined)
                {
                    bonbuUrMap.value[`MONTH${i}_AM`] = 0;
                }
            }
            for(let i = 1; i<13; i++)
            {
                bonbuUrMap.value[`MONTH${i}_AM`] += o[`MONTH${i}_AM`];
            }
        }
    }
}

async function getPersonUR()
{
    const res = await RestHelper.RestSend('USP_INWON_IPRYEOK','INWONUR',[{INWON_IPRYEOK_YEAR_CN : year.value}]);
    if(res._result[0].RESULTCD == 1)
    {
        personUrArr.value = [...res._data];
    }
}

function setUnit(value)
{
    return sf("{0:#,###,##0}",Number(value));
}

function setUnitPercent(value)
{
    return sf("{0:#,###,##0.##}",Number(value));
}
</script>

<style>
#PersonGrid .dx-toolbar-items-container{
    height: 0.8rem;
}
#PersonGrid .dx-item-content .dx-button-content{
    visibility: hidden;
    display: none;
}

#PersonGrid .dx-item-content .dx-widget{
    visibility: hidden;
    display: none;
}
.dx-datagrid .dx-row > td {
    height: 36px;
    padding: 0 12px;
    border:1px solid #d9d9d9 !important
}
#textOverflow {
    max-width: 90px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#DetailUR_View .p-highlight {
    background:#fff !important;
    color:#787878;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}
#DetailUR_View .p-selectbutton .p-button {
    background:#f8f8f8;
}
#DetailUR_View {
    overflow: auto;
}
#DetailUR_View .dx-treelist-headers .dx-treelist-table .dx-row > td,
#DetailUR_View .dx-treelist .dx-row > td {
    border: 1px solid #d9d9d9;
}
</style>