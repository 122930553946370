import RestHelper from "../Helpers/RestHelper";
class AccountViewModel{
    async USP_MENU_LIST()
    {
        const res = await RestHelper.RestSend('USP_MENU','LIST',[{}])
        if(res._result[0].RESULTCD === '1')
        {
            const data = [...res._data]
            let i = 1;
            for(const o of data)
            {
                o.index = i++;   
            }
            return data;    
        }
    }   
    async USP_MENU_INIT()
    {
        const res = await RestHelper.RestSend('USP_MENU','INIT',[{}])
        if(res._result[0].RESULTCD === '1')
        {
            return res._data;    
        }
    }
    async USP_MENU_UPDATE(data)
    {
        const res = await RestHelper.RestSend('USP_MENU','UPDATE',[data])
        if(res._result[0].RESULTCD === '1')
        {
            return res._data;    
        }
    }
    async USP_GROUP_LIST()
    {
        const res = await RestHelper.RestSend('USP_GROUP','LIST',[{}])
        if(res._result[0].RESULTCD === '1')
        {
            const data = [...res._data]
            let i = 1;
            for(const o of data)
            {
                o.index = i++;   
            }
            return data;    
        }
    }
    async USP_GROUP_UPDATE(data)
    {
        if(data.GROUP_SQ === null || data.GROUP_SQ === undefined)
        {
            const res = await RestHelper.RestSend('USP_GROUP','INSERT',[{
                GROUP_SQ : '',
                GROUP_CODE_CD : data?.GROUP_CODE_CD ?? '',
                GROUP_NAME_NM : data?.GROUP_NAME_NM ?? '',
                GROUP_BIGO_NM : data?.GROUP_BIGO_NM ?? '',
            }])
            if(res._result[0].RESULTCD === '1')
            {
                return res._data;    
            }
        }
        else
        {
            const res = await RestHelper.RestSend('USP_GROUP','UPDATE',[data])
            if(res._result[0].RESULTCD === '1')
            {
                return res._data;    
            }
        }  
    }
    async USP_GROUP_DELETE(id)
    {
        const res = await RestHelper.RestSend('USP_GROUP','DELETE',[{
            GROUP_SQ : id
        }])
        if(res._result[0].RESULTCD === '1')
        {
            return res._data;    
        }
    }
    async USP_ACCESS_LIST()
    {
        const res = await RestHelper.RestSend('USP_ACCESS','LIST',[{}])
        if(res._result[0].RESULTCD === '1')
        {
            const data = [...res._data]
            let i = 1;
            for(const o of data)
            {
                o.index = i++;   
            }
            return data;       
        }
    }
    async USP_ACCESS_UPDATE(ACCESS_SQ,GROUP_SQ,MENU_SQ)
    {
        if(ACCESS_SQ === undefined)
        {
            const res = await RestHelper.RestSend('USP_ACCESS','INSERT',[{
                ACCESS_SQ: '',
                GROUP_SQ: GROUP_SQ,
                MENU_SQ: MENU_SQ,
            }])
            if(res._result[0].RESULTCD === '1')
            {
                return res;       
            }
        }
        else
        {
            const res = await RestHelper.RestSend('USP_ACCESS','UPDATE',[{
                ACCESS_SQ: ACCESS_SQ,
                GROUP_SQ: GROUP_SQ,
                MENU_SQ: MENU_SQ,
            }])
            if(res._result[0].RESULTCD === '1')
            {
                return res;       
            }
        }
    } 
    async USP_ACCESS_DELETE(ACCESS_SQ)
    {
        const res = await RestHelper.RestSend('USP_ACCESS','DELETE',[{
            ACCESS_SQ: ACCESS_SQ,
        }])
        if(res._result[0].RESULTCD === '1')
        {
            return res;       
        }
    }

    async USP_GROUP_INWON_LIST()
    {
        const res = await RestHelper.RestSend('USP_GROUP_INWON','LIST',[{}])
        if(res._result[0].RESULTCD === '1')
        {
            let data = [...res._data]
            data = data.sort((a,b)=>{return a.GROUP_SQ - b.GROUP_SQ});
            let i = 1;
            let sq = data[0].GROUP_SQ;
            for(const o of data)
            {
                o.index = i;   
                if(sq != o.GROUP_SQ)
                {
                    sq = o.GROUP_SQ;
                    i++;
                }
            }
            return data;    
        }
    }   

    async USP_INWON_NEW_LIST()
    {
        const res = await RestHelper.RestSend('USP_INWON_NEW','LIST',[{}])
        if(res._result[0].RESULTCD === '1'){
            const data = [...res._data]
            return data;
        }
    }

    async USP_GROUP_INWON_INFO(id)
    {
        const res = await RestHelper.RestSend('USP_GROUP_INWON','INFO',[{
            GROUP_INWON_SQ : '',
            GROUP_SQ : id,
        }])
        if(res._result[0].RESULTCD === '1'){
            const data = [...res._data]
            return data;
        }
    }

    async USP_GROUP_INWON_INSERT(gid,id)
    {
        const res = await RestHelper.RestSend('USP_GROUP_INWON','INSERT',[{
            GROUP_INWON_SQ : '',
            GROUP_SQ : gid,
            INWON_SQ : id,
        }])
        return res;
        // if(res._result[0].RESULTCD === '1'){
        //     const data = [...res._data]
        //     return res;
        // }
    }

    async USP_GROUP_INWON_DELETE(gid,id)
    {
        const res = await RestHelper.RestSend('USP_GROUP_INWON','DELETE',[{
            GROUP_INWON_SQ : '',
            GROUP_SQ : gid,
            INWON_SQ : id,
        }])
        if(res._result[0].RESULTCD === '1'){
            const data = [...res._data]
            return data;
        }
    }

}

export default new AccountViewModel();