<template>
    <div class="w-full h-full px-[1rem] py-[1rem] bg-white overflow-auto">
        <div class="flex flex-row justify-between items-center w-full mb-[1rem] min-w-[1710px]">
            <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">매출관리</p>
            <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">저장</div>
        </div>
        <div class="h-4 w-full min-w-[1700px]"></div>
        <div class="w-full flex flex-row items-center justify-center min-w-[1710px]">
            <div class="flex flex-row items-center justify-between px-4 py-[1.2rem] rounded-lg bg-[#F3F4F5] w-full">
                 <div class="flex flex-row items-center w-[60%]">
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem] ">
                             프로젝트 코드
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold w-[4rem] mr-[2rem]">
                             {{ selProj.PJCODE_ID }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]">
                             PJ명
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj?.PJ_NM }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]">
                             주관부서
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj.BUSEOMYEONG_NM }}
                         </div>
                     </div>
                     <div class="flex flex-row w-auto"> 
                         <div class="text-[#92959B] font-medium text-[1.3rem]]">
                             신규/변경
                         </div>
                         <div class="ml-[1.2rem] text-[1.2rem] font-semibold mr-[2rem]">
                             {{ selProj.GITA_CD }}
                         </div>
                     </div>
                 </div>
                <div class="flex flex-row items-center w-auto">
                    <div class="flex flex-row items-center">
                        <input type="text" v-model="keyword" placeholder="PJ코드, PJ명 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[25rem]">
                        <div @click="projectSearch.show" class="flex flex-row items-center justify-center ml-[1rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                            <p class="text-white">검색</p>
                        </div>
                    </div>
                    <InputSwitch v-model="searchSwich" class="text-[1.4rem] ml-[2rem] mr-[1rem]" />
                    <p class="text-[1rem] font-semibold text-[#636363]">종료 프로젝트 검색</p>
                </div>
            </div>
        </div>
        <div class="h-[2rem] w-full min-w-[1700px]"></div>
        <div class="flex flex-row h-[calc(100%-14rem)]">
            <div class="flex flex-col h-full w-full min-w-[1710px]">
                <div class="flex flex-row justify-between items-center text-lg mb-[0.1rem]">
                    <div class="text-[1.3rem] text-[#c7c7c7] tracking-tighter">* 아래 표에서 매출을 입력하고 저장버튼을 클릭하세요.</div>
                    <div class="flex flex-row gap-[1rem]">
                        <p @click="historySidebar.open()" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] py-[0.4rem]">변경 조회</p>
                        <p @click="()=>{allPopupVisible = true}" class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] py-[0.4rem]">일괄입력</p>
                        <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="w-full h-[calc(100%-3rem)] flex flex-row mt-[1rem] min-w-[1710px]">
                    <div class="w-full">
                        <DataTable id="personGrid" dataKey="NO" v-model:selection="selPersonRow" :value="personList" editMode="cell" @cell-edit-complete="onCellEditComplete" class="border-[1px] h-full" scrollHeight="100%" selectionMode="single" scrollable stripedRows>
                            <ColumnGroup type="header">
                                <Row>
                                    <Column field="BUNRYU_NM" header="분류" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <Column field="SAWON_MYEONG_NM" header="성명" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <Column field="BUSEOMYEONG_NM" header="소속부서" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <Column header="월기성" :rowspan="2" frozen :style="tableRowStyle">
                                        
                                    </Column>
                                    <Column field="TUIP_GAEWOL_CN" header="투입개월" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <Column field="HYEONGTAE_NM" header="잔여MM" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <Column field="GROUP_CODE_CD" header="투입일" :rowspan="2" frozen :style="tableRowStyle"></Column>
                                    <template v-for="(y, index) in yearList" :key="index">
                                        <Column field="GROUP_CODE_CD" :header="y" :colspan="12" :style="tableRowStyle"></Column>
                                    </template>
                                </Row>
                                <Row>
                                    <template v-for="(y, index) in yearList" :key="index">
                                        <template v-for="(item, m) in 12" :key="m">
                                            <Column field="GROUP_CODE_CD" :header="`${m+1}월`" :style="tableRowStyle"></Column>
                                        </template>
                                    </template>
                                </Row>
                            </ColumnGroup>
                            <Column field="BUNRYU_NM" frozen></Column>
                            <Column field="SAWON_MYEONG_NM" frozen></Column>
                            <Column field="BUSEOMYEONG_NM" frozen></Column>
                            <Column field="WOLGISEONG_AM" frozen>
                                <template #body="{ data }">
                                    {{ setUnit(data.WOLGISEONG_AM) }}
                                </template>
                            </Column>
                            <Column field="TUIP_GAEWOL_CN" frozen>
                                <template #body="{ data }">
                                    {{setFloatUnit(data.TUIP_GAEWOL_CN)}}
                                </template>
                            </Column>
                            <Column field="" frozen>
                                <template #body="{ data }">
                                    {{setFloatUnit(getStayMM(data))}}
                                </template>
                            </Column>
                            <Column field="autoInput" frozen>
                                <template #editor="{ data }">
                                    <input v-model="TUIP_Il_CN" type="date" class="h-full bg-transparent"/>
                                </template>
                            </Column>
                            <template v-for="(y, index) in yearList" :key="index">
                                <template v-for="(item, m) in 12" :key="m">
                                    <Column :field="`MAECHUL_MONTH${m+1}_CN`">
                                        <template #body="{ data }">
                                            <div :class="isTarget(data[`MAECHUL_MONTH${m+1}_CN`][index])">
                                                {{ setFloatUnit(data[`MAECHUL_MONTH${m+1}_CN`][index]) }}
                                            </div>
                                        </template>
                                        <template #editor="{ data }">
                                            <InputText v-model="data[`MAECHUL_MONTH${m+1}_CN`][index]" autofocus class="h-full"/>
                                        </template>
                                    </Column>
                                </template>
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <Dialog header="일괄입력" v-model:visible="allPopupVisible" class="w-[50rem] h-[30rem] p-[1rem] bg-white" modal>
            <div class="flex flex-col gap-[1rem] w-full h-[20rem] overflow-hidden">
                <div class="flex flex-col">
                    <p class="font-semibold w-[4rem] leading-[2.4rem] text-[1.2rem]">대상</p>
                    <input class="border-[1px] bg-slate-100 mr-4 text-[1.2rem] rounded-lg w-[20rem] h-[3rem] pl-[.8rem]" type="text" v-model="selPersonRow.SAWON_MYEONG_NM" readonly/>
                </div>
                <div class="flex flex-col">                    
                    <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">기간</p>
                    <div class="w-full flex flex-row justify-between">
                        <input ref="allEditStDt" :min="calYear(selProj.YONGYEOK_SIJAK_DT)" :max="calYear(selProj.YONGYEOK_MAGAM_DT,false)" class="border-[1px] rounded-lg w-1/2 text-center pr-[.8rem] h-[3rem] text-[#818181]" type="month"/>
                        <p class="font-semibold leading-[2.8rem] text-2xl px-[.8rem] text-[#818181]">~</p>
                        <input ref="allEditEnDt" :min="calYear(selProj.YONGYEOK_SIJAK_DT)" :max="calYear(selProj.YONGYEOK_MAGAM_DT,false)" class="border-[1px] rounded-lg w-1/2 text-center  pr-[.8rem] text-[#818181]" type="month"/>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="w-1/2 pr-[.8rem]">
                        <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">간격</p>
                        <input ref="allEditGap" value="1" class="text-[1.2rem] border-[1px] mr-[2.4rem] leading-[2.6rem] rounded-lg w-full h-[3rem] pl-[.8rem]" type="number">
                    </div>
                    
                    <div class="w-1/2 pl-[1.6rem]">
                        <p class="font-semibold w-[4rem] leading-[2.4rem] text-xl">값</p>
                        <input ref="allEditValue" class="text-[1.2rem] border-[1px] mr-2 leading-[2.6rem] rounded-lg w-full h-[3rem] pl-[.8rem]" type="number"/>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-end">
                <div @click="onClickAllEdit" class="text-[1.2rem] bg-[#006EDD] cursor-pointer rounded-lg border-[#c7c7c7] text-[#ffffff] px-[2.4rem] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300">입력</div>
                <div @click="()=>{allPopupVisible = false}" class="ml-[1.6rem] text-[1.2rem] bg-[#ffffff] border-[1px] cursor-pointer rounded-lg text-[#818181] py-[1rem] tracking-tighter w-[10rem] text-center hover:bg-slate-300">닫기</div>
            </div>
        </Dialog>
        <ProjectSearchPopup ref="projectSearch" :search="keyword" :callBack="searchCallback">

        </ProjectSearchPopup>
        <HistorySidebar ref="historySidebar" menuName="C0060"/>
        <dic class="p-datatable-wrapper"/>
        <DxToast
        v-model:visible="toastVisible"
        v-model:message="toastMsg"
        v-model:type="toastType"
        :display-time="600"
        >
        <DxPosition my="top" at="top" offset="0 50"/>
        </DxToast>
    </div>
</template>

<script setup>
import {ref, watch, onMounted} from 'vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup'; 
import HistorySidebar from '../Controls/HistorySidebar.vue'; 
import InputText from 'primevue/inputtext';
import ProjectSearchPopup from '../Controls/ProjectSearchPopup.vue'
import InputSwitch from 'primevue/inputswitch';
import { DxToast, DxPosition } from 'devextreme-vue/toast';
import Dialog from 'primevue/dialog';
import vm from '../Models/Meachul_ViewModel'
import DataHelper from '../Helpers/DataHelper';
import sf from 'sf'
const tableRowStyle = 'padding: 0.7rem; border: 1px solid; border-color: #E5E7EB; min-width: 100px'

const keyword = ref('');
const selProj = ref({})
const projectSearch = ref(null)
const historySidebar = ref(null)
const personList = ref([])
const yearList = ref([])
const TUIP_Il_CN = ref(null);
const selPersonRow = ref({})
const allPopupVisible = ref(false)
const allEditStDt = ref(null)
const allEditEnDt = ref(null)
const allEditGap = ref(1)
const allEditValue = ref(null)
const toastVisible = ref(false)
const toastMsg = ref('')
const toastType = ref('success')

watch(selProj,async ()=>{
    updateYear();
    personList.value = await vm.USP_MAECHUL_LIST(selProj.value.PROJECT_SQ)
})

onMounted(async()=>{
    selProj.value = (await vm.USP_MAECHUL_INIT())[0];
})


const init = async ()=>{
    selProj.value = (await vm.USP_MAECHUL_INIT()).find(x=>x.PROJECT_SQ === selProj.value.PROJECT_SQ);
    updateYear();
    personList.value = await vm.USP_MAECHUL_LIST(selProj.value.PROJECT_SQ)
}
const searchCallback = (row) =>{
    selProj.value = row;
}

const updateYear = ()=>{
    yearList.value = [];
    yearList.value.push(DataHelper.SetDtData(selProj.value.YONGYEOK_MAGAM_DT).split('-')[0]);
    yearList.value.push(DataHelper.SetDtData(selProj.value.YONGYEOK_SIJAK_DT).split('-')[0]);
    yearList.value = [...new Set(yearList.value.sort())];
    if(yearList.value.length > 1)
    {
        const fy = Number(yearList.value[0]);
        const by = Number(yearList.value[1]);
        yearList.value = [];
        for(let i = fy; i<=by; i++)
        {
            yearList.value.push(i);
        }
    }
}
const setUnit = (value) =>{
    return sf("{0:###,###,###,###,###,###,##0}",Number(value));
}
const setFloatUnit = (value) =>{
    return sf("{0:###,###,###,###,###,###,##0.###}",Number(value));
}
const isTarget = (value) =>{
    const n = Number(value);
    if( n != 0)
    {
        return 'targetCell'
    }
    return ''
}
const onCellEditComplete = (e)=>{
    let { data, newValue, field } = e;
    if(field.includes('MAECHUL_MONTH'))
    {
        data[field] = newValue;
    }
    else if(field === 'autoInput')
    {
        data.TUIP_Il_CN = TUIP_Il_CN.value;
        vm.autoInput(data,selProj.value);
        TUIP_Il_CN.value = null;
    }
    else
    {
        e.preventDefault()
    }
}
const getStayMM = (data)=>{
    let ret = Number(data.TUIP_GAEWOL_CN)

    for(let m = 1; m<=12; m++)
    {
        for(const o of data[`MAECHUL_MONTH${m}_CN`])
        {
            ret -= Number(o);
        }
    }

    return setFloatUnit(ret);
}
const calYear = (dt,isSt=true)=>{
    const obj = new Date(DataHelper.SetDtData(dt,false));
    const ret = isSt ? `${obj.getFullYear()}-01` : `${obj.getFullYear()}-12`;
    return ret;
}

const onClickAllEdit = ()=>{
    const st = allEditStDt.value.value;
    const en = allEditEnDt.value.value;
    const gap = Number(allEditGap.value.value);
    const val = Number(allEditValue.value.value);
    if (isNaN(gap) || gap == 0) return;

    const isExist = !(DataHelper.StringNullorEmpty(st) || DataHelper.StringNullorEmpty(en) || isNaN(val));
    if (!selPersonRow.value) return;
    if (!isExist) return;
    const stDt = new Date(st);
    const enDt = new Date(en);

    for (let y = stDt.getFullYear(); y <= enDt.getFullYear(); y++) {
        const index = yearList.value.indexOf(y.toString());
        if(index == -1)break;
        const stMonth = stDt.getFullYear() == y ? stDt.getMonth() + 1 : 1;
        const enMonth = enDt.getFullYear() == y ? enDt.getMonth() + 1 : 12;
        for (let m = stMonth; m <= enMonth; m += gap) {
            if (m > 12) continue;
            selPersonRow.value[`MAECHUL_MONTH${m}_CN`][index] = val;
        }
    }
    allPopupVisible.value = false;
}

const onClickSave =async ()=>{
    const res = await vm.save(personList.value)
    if(res._result[0].RESULTCD == 1)
    {
        toastVisible.value = true;
        toastMsg.value = '저장 완료'
        toastType.value = 'success'
    }
    else
    {
        toastVisible.value = true;
        toastMsg.value = '저장 실패'
        toastType.value = 'error'
    }
}

</script>

<style>
#personGrid .p-datatable-wrapper{
    height: 100%;
}
#personGrid .p-datatable-wrapper td{
    height: 6rem;
    border-right: 1px solid #E4E5E7;
    border-bottom: 1px solid #E4E5E7;
    padding-left: 1rem;
}
#personGrid td:has(div.targetCell) 
{
    background: #FFF3C9;
}
</style>