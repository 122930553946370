<template>
    <div id="OutputMonthChart" class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR min-w-[38rem] max-w-[140rem]">
        <div class="flex flex-row justify-between items-start font-semibold px-[1.3rem] mb-[0.6rem] w-full">
            <div class="flex flex-row justify-start w-full">
                <h1 class="text-[1.6rem] w-[10rem]">월간 실적</h1>
                <p class="w-full text-start text-[1rem] text-[#92959B] leading-[2rem] font-semibold">(단위 : 억원)</p>
            </div>
        </div>
        <div class="flex flex-row items-center text-[1.1rem] px-4 mb-[1rem]">
            <div class="bg-[#E2E2E2] w-[0.8rem] h-[0.8rem] p-[0.4rem] rounded-lg mr-[0.4rem]"></div>
            <span>목표</span>
            <div class="bg-[#EC77A1] w-[0.8rem] h-[0.8rem ] p-[0.4rem] rounded-[2rem] mr-[0.4rem] ml-[1.2rem]"></div>
            <span>실적</span>
        </div>
        <Bar :options="chartOptions" :data="chartData" ref="barChart" height="200" v-if="loaded" :plugins="[ChartJSPluginDatalabels]"/>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import RestHelper from '../../../Helpers/RestHelper';
import LogHelper from '../../../Helpers/LogHelper';
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
    name: "OutputMonthChart",
    components: {
        Bar,

    },
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loaded: false,
            chartData: {
                labels: ['수주', '수금'],
                datasets: [
                    {
                        label: '목표',
                        data: [],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    },
                    {
                        label: '실적',
                        data: [],
                        backgroundColor: '#EC77A1',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                borderRadius: 8,
                layout: {
                    autoPadding: false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: false,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: function (value, context) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                    },
                }
            },

        }
    },
    async mounted() {
        await this.get();
    },
    watch: {
        async year() {
            await this.get();
        },
        async month() {
            await this.get();
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        },
        barChart()
        {
            return this.$refs.bar.chart;
        },
        chartData()
        {
            return this.chartData;
        },
        chartOptions()
        {
            return this.chartOptions;
        },
    },
    methods: {
        async get() {
            this.loaded = false;
            try {
                const res = await RestHelper.RestSend('USP_BORD', 'BORD1', [{ BORD_YEAR_CN: this.year, BORD_MONTH_CN: this.month }]);
                LogHelper.logWrite('월간실적 데이터 요청', [res], this.logUse);
                if (res._result[0].RESULTCD == '1') {
                    const data = res._data01[0];
                    // chartData 업데이트
                    this.chartData.datasets[0].data = [this.getNumber(data.수주계획), this.getNumber(data.수금계획)];
                    this.chartData.datasets[1].data = [this.getNumber(data.수주실적), this.getNumber(data.수금실적)];
                    this.loaded = true;
                    this.updateChart();
                }
            } catch (error) {
                console.error("데이터 가져오기 실패:", error);
            }
            // 차트 유동적으로 조정~..
            this.$nextTick(() => {
                const tempOption = {...this.chartOptions};
                tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                tempOption.scales.y.max = Math.max(...this.chartData.datasets.map(x => Math.max(...x.data))) * 1.3;
                this.chartOptions = { ...tempOption };
            });
        },
        getNumber(str) {
            let unit = 10;
            return Math.round(Number(str) * unit) / unit;
        },
        updateChart() {
            // 차트 업데이트
            if (this.$refs.barChart && this.$refs.barChart.chart) {
                this.$refs.barChart.chart.update();
            }
        },
    },
};
</script>

<style scoped>
#OutputMonthChart canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 600px !important;
    max-height: 200px !important;
}
</style>
