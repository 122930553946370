<template>
    <div class="grid grid-rows-12 border-[1px] p-[1.6rem] rounded-[1.4rem] shadow-lg font-NotoSansKR">
        <div class="row-start-1 flex flex-row justify-between items-end font-semibold px-[1.3rem] mb-[0.6rem]">
            <h1 class="text-[1.6rem]">실적현황 요약</h1>
            <h1 class="text-[1.1rem] text-[#ADAEB1]">기준일 : {{ today }}</h1>
        </div>
        <div class="row-start-2 flex flex-row justify-between p-[1rem] h-[12rem]"> 
            <div class="flex flex-col items-center justify-center text-center p-[1rem] w-[46%] bg-[#FAFAFA] rounded-[1.6rem] shadow-sm">
                <p class="text-[1.4rem] font-semibold">누적 수주 실적</p>
                <p class="text-[2.4rem] font-bold mt-[1rem]">{{data01}}억</p>
            </div>
            <div class="flex flex-col items-center justify-center text-center p-[1rem] w-[46%] bg-[#FAFAFA] rounded-[1.6rem] shadow-sm">
                <p class="text-[1.4rem] font-semibold">계획 대비 달성율</p>
                <p class="text-[2.4rem] font-bold mt-[1rem]">{{data02}}%</p>
            </div>
        </div>
        <div class="row-start-3 flex flex-row justify-between p-[1rem] h-[12rem]"> 
            <div class="flex flex-col items-center justify-center text-center p-[1rem] w-[46%] bg-[#FAFAFA] rounded-[1.6rem] shadow-sm">
                <p class="text-[1.4rem] font-semibold">누적 수금 실적</p>
                <p class="text-[2.4rem] font-bold mt-[1rem]">{{data03}}억</p>
            </div>
            <div class="flex flex-col items-center justify-center text-center p-[1rem] w-[46%] bg-[#FAFAFA] rounded-[1.6rem] shadow-sm">
                <p class="text-[1.4rem] font-semibold">계획 대비 달성율</p>
                <p class="text-[2.4rem] font-bold mt-[1rem]">{{data04}}%</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import RestHelper from '../../../Helpers/RestHelper'
import LogHelper from '../../../Helpers/LogHelper'
import moment from 'moment'

export default defineComponent({
    props: {
        year : Number,
        month : Number,
    },
    data() {
        return {
            data01 : 0,
            data02 : 0,
            data03 : 0,
            data04 : 0,
            today : moment().format("YYYY-MM-DD"),
        }
    },
    computed: {},
    watch: {
        async year()
        {
            await this.get();
        },
        async month()
        {
            await this.get();
        }
    },
    async mounted()
    {
        await this.get();
    },
    methods: {
        async get(){
            const res = await RestHelper.RestSend('USP_BORD','BORD1',[{BORD_YEAR_CN : this.year,BORD_MONTH_CN:this.month}]);
            LogHelper.logWrite('실적현황 데이터 요청',[res],this.logUse)
            if(res._result[0].RESULTCD == '1')
            {
                const dataSource = res._data[0];
                this.data01 = Math.round(Number(dataSource.누적수주실적)*10)/10;
                this.data02 = Math.round(Number(dataSource.계획대비달성율_수주)*10)/10;
                this.data03 = Math.round(Number(dataSource.누적수금실적)*10)/10;
                this.data04 = Math.round(Number(dataSource.계획대비달성율_수금)*10)/10;
            }
        }
    },
});
</script>

<style lang="scss" scoped>


</style>