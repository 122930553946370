<template>
    <div class="flex flex-col w-full h-full p-[1rem] rounded-[1.4rem] font-NotoSansKR" id="ChartWrapper">
        <div class="flex flex-row items-center justify-between mb-[1rem]">
            <div class="font-semibold text-[1.6rem]">{{ title }}</div>
            <div class="font-normal text-[1.3rem] text-[#62686A]">누적합계 (단위: 억원)</div>
        </div>
        <div class="flex flex-row items-center justify-between mb-[1rem]">
            <div class="flex flex-row items-center text-[11px] text-[#62686A]">
                <template v-for="(item, index) in this.dataSource" :key="index">
                    <div :style="{background : `#${item.BUSEO_COLOR}`}" :class="`border-[1px] w-[1rem] h-[1rem] p-[0.4rem] rounded-[2rem] mr-[0.4rem]`"></div>
                    <span class="mr-[1rem]">{{ item.BUSEO_NAME }}</span>
                </template>
            </div>
            <p class="font-semibold text-[1.6rem]">{{total}}억</p>
        </div>
        <div class="w-full h-[calc(100%-4rem)]">
            <Bar :options="barChartOptionsObj" :data="barChartDataObj" :plugins="[ChartJSPluginDatalabels]"/>
        </div>
    </div>
</template>

<script>
import RestHelper from '../../Helpers/RestHelper'
import LogHelper from '../../Helpers/LogHelper'
import { Bar, getDatasetAtEvent } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement,LineElement } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
import DataHelper from '@/components/Helpers/DataHelper'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement)

export default {
    components:{
        Bar
    },
    props:{
        year : Number,
        title : String,
    },
    watch : {
        async year()
        {
            if(this.year == null)return;
            await this.get();
        }
    },
    data(){
        return{
            dataSource : [],
            total : 0,
            barChartDataObj: {
                labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                datasets: [],
            },
            barChartOptionsObj: {
                responsive: true,
                maintainAspectRatio : false,
                borderRadius: 8,
                layout: {
                    autoPadding : false,
                    padding: {
                        top: 0
                    }
                },
                scales: {
                    y: {
                        display: true,
                        max: 120,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, ticks) {
                                return Math.floor(value/100000000);
                            },
                            stepSize : 100000000,
                        },
                    },
                    x:{
                        display: true,
                        grid: {
                            display: false
                        }
                    }
                },
                plugins : {
                    legend : {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                        anchor: 'end',
                        align: 'top',
                        formatter: function(value, context) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'%';
                        },
                        color: 'black',
                        font: {
                            weight: 'normal'
                        }
                    },
                }
            }
        }
    },
    async mounted()
    {
        await this.get();
    },
    methods : {
        async get()
        {
            const res = await RestHelper.RestSend('USP_BORD','BORD2', [{ BORD_YEAR_CN : this.year }]);
            if(res._result[0].RESULTCD == '1')
            {
                this.barChartDataObj.datasets = [];
                this.dataSource = [...res._data01];
                const monthAvg = this.dataSource.find(x=>x.BUSEO_NAME === '월별합계');
                monthAvg.BUSEO_COLOR = 'E2E2E2'
                for(const data of this.dataSource)
                {
                    const obj = {
                        data : [data.MONTH1,data.MONTH2,data.MONTH3,data.MONTH4,data.MONTH5,data.MONTH6,data.MONTH7,data.MONTH8,data.MONTH9,data.MONTH10,data.MONTH11,data.MONTH12],
                        backgroundColor: '#E2E2E2',
                        barPercentage: 0.6,
                        categoryPercentage: 0.7,
                    }
                    if(data.BUSEO_NAME != '월별합계')
                    {
                        obj.type = 'line';
                    }
                    else
                    {
                        this.total = data.MONTH1+data.MONTH2+data.MONTH3+data.MONTH4+data.MONTH5+data.MONTH6+data.MONTH7+data.MONTH8+data.MONTH9+data.MONTH10+data.MONTH11+data.MONTH12;
                        this.total /= 100000000;
                        this.total = Math.floor(this.total);
                    }
                    if(!DataHelper.StringNullorEmpty(data.BUSEO_COLOR))
                    {
                        obj.backgroundColor = '#'+data.BUSEO_COLOR;
                        obj.borderColor = '#'+data.BUSEO_COLOR;
                    }
                    this.barChartDataObj.datasets.push(obj);
                }
                this.barChartOptionsObj.scales.y.max = Math.floor((Math.max(...this.barChartDataObj.datasets.map(x=>Math.max(...x.data)))*1.3)/100000000)*100000000;
                
                const tempData = {...this.barChartDataObj};
                const tempOptions = {...this.barChartOptionsObj};
                tempOptions.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x=>Math.max(...x.data)))*1.3;
                this.barChartDataObj = {};
                this.barChartOptionsObj = {};

                this.$nextTick(()=>{
                    this.barChartDataObj = {...tempData};
                    this.barChartOptionsObj = {...tempOptions};

                    const tempOption = {...this.barChartOptionsObj};
                    tempOption.scales = { ...tempOption.scales };  // 반응성을 위한 복사
                    tempOption.scales.y = { ...tempOption.scales.y };  // 반응성을 위한 복사
                    tempOption.scales.y.max = Math.max(...this.barChartDataObj.datasets.map(x => Math.max(...x.data))) * 1.3;
                    if(tempOption.scales.y.max == 0)
                    {
                        tempOption.scales.y.max = 10;
                    }
                    this.barChartOptionsObj = { ...tempOption };
                })

            }
        }
    },
    computed: {
        ChartJSPluginDatalabels()
        {
            return ChartJSPluginDatalabels;
        }
    }
}
</script>

<style>
#ChartWrapper {
    box-shadow: 0px 2px 12px 0px rgba(2, 73, 172, 0.13);

}
</style>