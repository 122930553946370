import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import 'devextreme/dist/css/dx.material.blue.light.css';
import 'primevue/resources/themes/saga-blue/theme.css'; // 테마 파일
import 'primevue/resources/primevue.min.css'; // core css
import 'primeicons/primeicons.css'; // icons
import 'primeflex/primeflex.css';
import './index.css'
import router from './router';
import BadgeDirective from 'primevue/badgedirective';

const app = createApp(App)

app.config.warnHandler = (msg, instance, trace) =>
  ![
    'built-in or reserved HTML elements as component id: component',
    '"class" is a reserved attribute and cannot be used as component prop',
    'Cannot find element: #__nuxt'
  ].some((warning) => msg.includes(warning)) &&
  console.warn('[Vue warn]: '.concat(msg).concat(trace))

app.directive('badge', BadgeDirective);
app.use(PrimeVue, {
    locale: {
      firstDayOfWeek: 1,
      dayNames: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
      dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
      dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"],
      monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
      monthNamesShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
      today: '오늘',
      clear: '지우기',
      dateFormat: 'yy-mm-dd',
      weekHeader: '주'
    }
  });
app.use(router)
app.mount('#app')

app.config.globalProperties.logUse = true;