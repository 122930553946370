export default class BIYONGModel{
    constructor(i,BUSEO_SQ,year)
    {
        this.BIYONG_YEAR_CN = year; //BIYONG_YEAR_CN
        this.SANGTAE_NM = 0; //SANGTAE_NM
        this.BONBU_SQ = BUSEO_SQ; //BUSEO_SQ
        this.BIYONG_MONTH_CN = i;  // BIYONG_MONTH_CN
        this.INGEONBI_AM = 0; //INGEONBI_AM
        this.OEJUBI_AM = 0; //OEJUBI_AM
        this.GYEONGBI_AM = 0; //GYEONGBI_AM
        this.BUSEO_GONGTONGBI_AM = 0; //BUSEO_GONGTONGBI_AM
        this.JEONSA_GANJEOPBI_AM = 0; //JEONSA_GANJEOPBI_AM
        this.BIYONG_SQ = i;  // BIYONG_SQ
    }
}