import DataHelper from "./DataHelper";
export default class LogHelper{
    static logWrite(msg,arr=[],isUse = true)
    {
        if(!isUse)return;
        const dt = DataHelper.getDate();
        // console.log(`${dt} > ${msg}`,arr);
    }
}

