<template>
    <div class="w-auto h-full px-[1rem] py-[1rem] bg-white" id="PJ_view">
      <div class="flex flex-row justify-between items-center w-full mb-[1rem]">
          <p class="text-[2.2rem] leading-[2.6rem] font-semibold text-[#2b3133] tracking-tighter">PJ관리</p>
          <div @click="onClickSave" class="w-[13rem] h-[3rem] text-center select-none text-white bg-[#006EDD] rounded-lg cursor-pointer flex flex-row items-center justify-center">저장</div>
      </div>
      <div class="w-full flex flex-row items-center justify-between">
          <!-- <DxSelectBox :data-source="sujuList" v-model="selSearch" :search-expr="['SUJU_NAME']" placeholder="프로젝트 검색 or 선택" :search-enabled="true"  display-expr="SUJU_NAME" class="w-[48rem] border-[1px] text-left pl-[1.2rem] h-[3.7rem] mt-[.3rem] rounded-lg border-[#C7C7C7] vertical-middle text-[1.4rem] text-[#818181]"/> -->
          <!-- <div class="py-[0.8rem] px-[2rem] border-[1px] text-[#555] border-[#aaa] box-border rounded-xl ml-[1rem]">상세 검색</div> -->
          <!-- <p class="text-[2rem]">{{ selProj.PJ_NM }}</p> -->
          
      </div>
      <div class="w-full flex flex-row items-center justify-center mt-[1rem]">
        <div class="flex flex-row items-center px-4 py-[1.2rem] rounded-lg bg-[#F3F4F5] w-full">
            <!-- PJCODE, 수주분류, 용역시작일, 용역마감일 데이터 가져와야 함 -->
            <div class="flex flex-row w-auto"> 
                <div class="text-[#92959B] font-medium text-[1.4rem] ">
                    프로젝트 코드
                </div>
                <div class="ml-[1.2rem] text-[1.4rem] font-semibold w-[4rem] mr-[2rem]">
                    {{ selProj.PJCODE_ID }}
                </div>
            </div>
            <div class="flex flex-row w-auto"> 
                <div class="text-[#92959B] font-medium text-[1.4rem]">
                    PJ명
                </div>
                <div class="ml-[1.2rem] text-[1.4rem] font-semibold w-[46rem] mr-[2rem]">
                    {{ selProj.PJ_NM }}
                </div>
            </div>
            <div class="flex flex-row w-auto"> 
                <div class="text-[#92959B] font-medium text-[1.4rem]]">
                    주관부서
                </div>
                <div class="ml-[1.2rem] text-[1.4rem] font-semibold w-[14rem] mr-[2rem]">
                    {{ selProj.BUSEOMYEONG_NM}}
                </div>
            </div>
            <div class="flex flex-row w-auto"> 
                <div class="text-[#92959B] font-medium text-[1.4rem]">
                    신규/변경
                </div>
                <div class="ml-[1.2rem] text-[1.4rem] font-semibold w-[9.4rem] mr-[2rem]">
                    {{ selProj.GITA_CD }}
                </div>
            </div>
            <div class="flex flex-row items-center">
                <input type="text" v-model="keyword" placeholder="PJ코드, PJ명 검색" class="border-[1px] px-[1rem] rounded-lg h-[3.7rem] w-[25rem]">
                <div @click="projectSearch.show" class="flex flex-row items-center justify-center ml-[2rem] rounded-lg w-[11rem] h-[3.7rem] bg-[#787878] select-none cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#fff" class="w-[1.6rem] h-[2rem]">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    <p class="text-white">검색</p>
                </div>
            </div>
            <InputSwitch v-model="searchSwich" class="text-[1.4rem] ml-[6rem] mr-[1rem]" />
            <p class="text-[1.2rem] text-[#787878]">종료 프로젝트 검색</p>
        </div>
    </div>
      <div class="grid grid-cols-12 items-center justify-center border-t-2 border-l-2 grid-rows-2 my-[1.5rem] mx-[0rem] w-full">
          <div class="col-start-10 row-start-1 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              수주분류</div>
          <div class="col-start-11 col-span-2 row-start-1 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[#818181]">
              {{ selProj.BUNRYU_CD }}
          </div>
          <div class="col-start-1 row-start-1 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              기준금액</div>
          <div class="col-start-2 col-span-2 row-start-1 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem]">
              <input @change="onGijunChange" v-model="selProj.GIJUN_GEUMAEK_AM" class="w-full h-full text-left flex items-center text-[#818181]" type="number"/></div>
          <div class="col-start-4 row-start-1 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              계약금액</div>
          <div class="col-start-5 col-span-2 row-start-1 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem]">
              <input v-model="selProj.GYEYAK_GEUMAEK_AM" class="w-full h-full text-left flex items-center text-[#818181]" type="number" readonly/></div>
          <div class="col-start-7 row-start-1 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              제외금액</div>
          <div class="col-start-8 col-span-2 row-start-1 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left flex items-center pl-[1.6rem] text-[1.4rem] text-[#818181]">
              {{ selProj.GYEYAK_GEUMAEK_AM - selProj.GIJUN_GEUMAEK_AM }}</div>
           <div class="col-start-1 row-start-2 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              용역기간</div>
           <div class="col-start-2 col-span-3 row-start-2 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left">
              <div class="w-full h-full flex flex-row justify-center items-center px-[1.6rem]">
                  <input class="w-1/2 h-[3rem] border-[1px] px-[1rem] text-center rounded-lg text-[1.4rem] text-[#818181]" v-model="selProj.SIJAK_DT" type="date"/>
                  <p class="leading-[4rem] mx-8 text-[1.4rem] text-[#818181]">~</p>
                  <input class="w-1/2 h-[3rem] border-[1px] px-[1rem] text-center rounded-lg text-[1.4rem] text-[#818181]" v-model="selProj.MAGAM_DT" type="date"/>
              </div>
            </div>
            <div class="col-start-5 row-start-2 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
              비상주기간</div>
              <div class="col-start-6 col-span-3 row-start-2 bg-[#fff] border-r-2 border-b-2 h-[3.7rem] text-left">
                  <div class="w-full h-full flex flex-row justify-center items-center px-[1.6rem]">
                      <input class="w-1/2 h-[3rem] border-[1px] px-[1rem] text-center rounded-lg text-[1.4rem] text-[#818181]" v-model="selProj.BI_SIJAK_DT" type="date"/>
                      <p class="leading-[4rem] mx-8 text-[1.4rem] text-[#818181]">~</p>
                      <input class="w-1/2 h-[3rem] border-[1px] px-[1rem] text-center rounded-lg text-[1.4rem] text-[#818181]" v-model="selProj.BI_MAGAM_DT" type="date"/>
                    </div>
                </div>
            <div class="col-start-9 row-start-2 bg-[#fafafa] border-r-2 border-b-2 h-[3.7rem] text-left pl-[1.6rem] flex items-center text-[1.4rem] text-[#818181]">
                프로젝트 분류</div>
            <DxSelectBox :data-source="projType" v-model="selProj.PJ_BUNRYU_CD" @change="onChangeSelectBox" placeholder="프로젝트 분류" class="w-full text-left pl-[1.2rem] h-[3.7rem] rounded-lg border-[#C7C7C7] border-[1px] vertical-middle text-[1.4rem] text-[#818181] col-start-10 col-span-3 row-start-2"/>
      </div>
      <div class="flex flex-col h-[calc(100%-22rem)] w-full text-[#818181]">
        <div v-show="selProj.PJ_BUNRYU_CD == '환산인월수' && selProj.BUNRYU_CD?.includes('공공')" class="grid grid-cols-7 grid-rows-2 m-[0px] items-center w-full border-t-2 border-l-2 h-[6rem] mb-[2rem]">
            <div class="col-start-1 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">시공단계 총 MM</div>
            <div class="col-start-1 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">비상주 비율</div>
            <div class="col-start-2 row-start-1 border-r-2 border-b-2 h-[2.9rem] w-full flex items-center">
                <DxNumberBox v-model="sigongMM" format="###,##0.###" :focus-state-enabled="false" class="w-full h-[2.5rem]"/>
            </div>
            <div class="col-start-2 row-start-2 border-r-2 border-b-2 h-[2.9rem] w-full flex items-center">
                <DxNumberBox v-model="beRate" format="###,##0.###'%'" :focus-state-enabled="false" class="w-full h-[2.5rem]"/>
            </div>
            <div class="col-start-3 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">총 비상주 인원수</div>
            <div class="col-start-3 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">1인 기준 비상주 총 MM</div>
            <div class="col-start-4 row-start-1 border-r-2 border-b-2 h-[2.9rem] w-full flex items-center">
                <DxNumberBox v-model="beCount" format="###,##0.###" :focus-state-enabled="false" class="w-full h-[2.5rem]"/>
            </div>
            <div class="col-start-4 row-start-2 border-r-2 border-b-2 h-full w-full flex items-center pl-[0.8rem]">
                <p class="w-full h-[2.5rem] flex flex-row items-center">{{ getBisangMMTotal() }}</p>
            </div>
            <div class="col-start-5 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">1인 기준 비상주 MM (한달 기준)</div>
            <div class="col-start-6 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">{{ getBisangMMTotal() / getTotalMonths(selProj.BI_SIJAK_DT,selProj.BI_MAGAM_DT)}}</div>
            <div class="col-start-7 row-span-2 border-r-2 border-b-2 h-full w-full p-[0.2rem] flex items-center justify-center select-none">
                <p @click="workTypeChanged" class="bg-[#006EDD] text-white font-semibold w-full h-full flex items-center justify-center rounded-lg hover:bg-[#003bdd]">적용</p>
            </div>
        </div>
        <div v-show="selProj.PJ_BUNRYU_CD == '환산인월수' && selProj.BUNRYU_CD?.includes('주택법')" class="grid grid-cols-9 grid-rows-2 m-[0px] items-center w-full border-t-2 border-l-2 h-[6rem] mb-[2rem]">
            <div class="col-start-1 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">근무형태</div>
            <div class="col-start-2 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">상주</div>
            <div class="col-start-2 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">비상주</div>
            <div class="col-start-3 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">환산인월수</div>
            <div class="col-start-4 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">
                <DxNumberBox v-model="sangSum" format="###,##0" :focus-state-enabled="false" class="w-full"/>
            </div>
            <div class="col-start-4 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">
                <DxNumberBox v-model="beSum" format="###,##0" :focus-state-enabled="false" class="w-full"/>
            </div>
            <div class="col-start-5 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">비율</div>
            <div class="col-start-6 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">{{isNaN(sangSum/((sangSum+beSum)/100)) ? 0 : Math.round((sangSum/((sangSum+beSum)/100))*1000)/1000}}%</div>
            <div class="col-start-6 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">{{isNaN(beSum/((sangSum+beSum)/100)) ? 0 : Math.round((beSum/((sangSum+beSum)/100))*1000)/1000}}%</div>
            <div class="col-start-7 row-span-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] bg-[#fafafa] flex items-center">월UR</div>
            <div class="col-start-8 row-start-1 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">1.0</div>
            <div class="col-start-8 row-start-2 border-r-2 border-b-2 h-full w-full pl-[1.6rem] flex items-center">{{isNaN((beSum/sangSum))? 0 : Math.round((beSum/sangSum)*1000)/1000}}</div>
            <div class="col-start-9 row-span-2 border-r-2 border-b-2 h-full w-full p-[0.2rem] flex items-center justify-center select-none">
                <p @click="workTypeChanged" class="bg-[#006EDD] text-white font-semibold w-full h-full flex items-center justify-center rounded-lg hover:bg-[#003bdd]">적용</p>
            </div>
        </div>
        <div class="flex flex-row gap-[1rem] justify-end text-[#818181] text-[1.2rem] font-semibold pl-4 py-2">
            <div class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] py-[0.4rem]" @click="onClickAdd">인원추가</div>
            <div class="cursor-pointer select-none text-[1.1rem] border-[#c2c2c2] border-2 rounded-lg px-[2rem] py-[0.4rem]" @click="historySidebar.open()">변경 조회</div>
            <div @click="init" class="select-none cursor-pointer w-auto h-full border-[1px] p-[0.4rem] rounded-lg hover:bg-slate-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-[1.6rem]">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
        <DxDataGrid ref="pmGrid" key-expr="index" :data-source="personList" :height="selProj.PJ_BUNRYU_CD == '환산인월수' ? 'calc(100% - 10rem)' : 'calc(100% - 4rem)'" :show-borders="true" class="select-none border-2 border-[#e0e0e0] overflow-y-visible"
        @cellClick="onCellClick($event)" @cell-prepared="onCellPrepared($event)"
        @cell-hover-changed="onCellHoverChanged($event)">
            <DxPaging :enabled="false"/>
            <DxSelection mode="single"/>
            <DxHeaderFilter :visible="false"/>
            <DxScrolling mode="'virtual"/>
            <DxLoadPanel :enabled="true"/>
            <DxEditing startEditAction="dblClick" mode="cell" :allow-updating="true" :allow-adding="false" :allow-deleting="true"></DxEditing>
            <DxKeyboardNavigation
                :edit-on-key-press="true"
                enter-key-action="column"
                enter-key-direction="moveFocus"
            />
            <DxRowDragging
                :allow-reordering="true"
                :on-reorder="onReorder"
                :show-drag-icons="true"
            />
            <DxColumn caption="분류" cell-template="burnyu-cell-template" :allowEditing='false' data-field="BUNRYU_NM" alignment="center" class="border-[1px]"/>
            <DxColumn caption="성명" cell-template="inwon-cell-template" :allowEditing='true' data-field="USER_NM" alignment="center">
                <DxLookup :dataSource="inwonList" value-expr="SAWON_MYEONG_NM" display-expr="INWON_NAME"/>
            </DxColumn>
            <DxColumn caption="직접인건비" v-if="selProj.PJ_BUNRYU_CD != '환산인월수'" alignment="center">
                <DxColumn caption="금액" format="###,##0" data-field="JIKJEOP_INGEONBI_AM" alignment="right"/>
                <DxColumn caption="비율" format="##0.###'%'" :allowEditing='false' data-field="JIKJEOP_INGEONBI_RT" alignment="right"/>
            </DxColumn>
            <DxColumn caption="환산인월수" v-if="selProj.PJ_BUNRYU_CD == '환산인월수'" alignment="center">
                <DxColumn caption="투입인월수" format="###,##0" data-field="JIKJEOP_INGEONBI_AM" alignment="center"/>
                <DxColumn caption="투입비율" :allowEditing='false'  data-field="JIKJEOP_INGEONBI_RT" alignment="center"/>
            </DxColumn>
            <DxColumn caption="인당 총 인건비" format="###,##0" :allowEditing='false' data-field="INDANG_INGEONBI_AM" alignment="right"/>
            <DxColumn caption="투입개월" format="###,##0.###" :allowEditing='true' data-field="TUIP_GAEWOL_CN" alignment="right"/>
            <DxColumn caption="월기성" format="###,##0" :allowEditing='false'  data-field="WOLGISEONG_AM" alignment="right"/>
            <DxColumn caption="근무형태" :allowEditing='true' data-field="BIGO_NM" alignment="center">
                <DxLookup :dataSource="workTypes" value-expr="title" display-expr="title"/>
            </DxColumn>
            <template #burnyu-cell-template="{ data: data }">
                <input @blur="onBunryuBlur($event,data)" type="text" class="w-full h-full bg-transparent" :value="data.row.data.BUNRYU_NM" />
            </template>
            <template #inwon-cell-template="{ data: data }">
                <div>{{ setINWON(data) }}</div>
            </template>
            <DxSummary>
                <DxTotalItem display-format="인원: {0}" column="SAWON_MYEONG_NM" summary-type="count"/>
                <DxTotalItem display-format="{0}" value-format="###,###,###,###,###,##0" column="JIKJEOP_INGEONBI_AM" summary-type="sum"/>
                <DxTotalItem display-format="{0}%" value-format="##0.###" column="JIKJEOP_INGEONBI_RT" summary-type="sum"/>
                <DxTotalItem display-format="{0}" value-format="###,###,###,###,###,##0" column="INDANG_INGEONBI_AM" summary-type="sum"/>
                <DxTotalItem display-format="{0}" value-format="###,###,###,###,###,##0.###" column="TUIP_GAEWOL_CN" summary-type="sum"/>
            </DxSummary>
        </DxDataGrid>
      </div>
      <ProjectSearchPopup ref="projectSearch" :search="keyword" :callBack="searchCallback">

      </ProjectSearchPopup>
      <HistorySidebar ref="historySidebar" menuName="C0050"/>
    </div>
  </template>
  
  <script>
  import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxHeaderFilter,
    DxScrolling,
    DxSelection,
    DxLoadPanel,
    DxKeyboardNavigation,
    DxRowDragging,
    DxLookup,
    DxSummary,DxTotalItem
    } from 'devextreme-vue/data-grid';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import RestHelper from '../Helpers/RestHelper';
  import DataHelper from '../Helpers/DataHelper';
  import LogHelper from '../Helpers/LogHelper';
  import ClipboardHelper from '../Helpers/ClipboardHelper';
  import { DxNumberBox } from "devextreme-vue/number-box";
  import ProjectSearchPopup from '../Controls/ProjectSearchPopup.vue'
  import InputSwitch from 'primevue/inputswitch';
  import HistorySidebar from '../Controls/HistorySidebar.vue';
  import InputText from 'primevue/inputtext';

  export default {
      components:{
          DxDataGrid,
          DxColumn,
          DxPaging,
          DxEditing,
          DxHeaderFilter,
          DxScrolling,
          DxSelection,
          DxLoadPanel,
          DxKeyboardNavigation,
          DxSelectBox,
          DxLookup,HistorySidebar,
          DxRowDragging,InputText,
          DxNumberBox,InputSwitch,
          DxSummary,DxTotalItem,ProjectSearchPopup
      },
      computed:{
          pmGrid()
          {
              return this.$refs.pmGrid.instance;
          },
          workTypes()
          {
              return [
                  {
                      title : '상주'
                  },
                  {
                      title : '비상주'
                  },
                  {
                      title : '설계'
                  },
                  {
                      title : '기타'
                  },
              ]
          },
          projType(){
            return ['직접인건비','환산인월수']
          },
          projectSearch()
          {
            return this.$refs.projectSearch;
          },
          historySidebar()
          {
            return this.$refs.historySidebar;
          }
      },
      data(){
          return{
              keyword : '',
              searchSwich : false,
              sujuList : [],
              selSearch : {},
              optionList : [],
              projList:[],
              selProj : {},
              tempPersonList : [],
              personList : [],
              inwonList :[],
              sigongMM : 0,
              beRate : 0,
              beCount : 0,
              sangSum : 0,
              beSum : 0,
          }
      },
      watch:{
          async selSearch()
          {
            if(DataHelper.StringNullorEmpty(this.selSearch?.PJ_NM))
                return;
            
            await this.initProject();
            await this.findProj();
            this.sangSum = 0;
            this.beSum = 0;
            for(const o of this.personList)
            {
                const value = Number(o.JIKJEOP_INGEONBI_AM);
                if(isNaN(value))continue;
                if(o.BIGO_NM == '상주')
                {
                    this.sangSum += value;
                }
                else
                {
                    this.beSum += value;
                }
            }
          }
      },
      async created()
      {
          await this.initSUJU();
          await this.initProject();
          await this.initInwon();
          await this.initOptions();
          this.selSearch = this.sujuList[0];
      },
      mounted()
      {
      },
      methods:{
        init()
        {
            const temp = {...this.selSearch}
            this.selSearch = {};
            this.$nextTick(()=>{
                this.selSearch = {...temp}
            })
        },
        workTypeChanged(e)
        {
            if(this.selProj.BUNRYU_CD?.includes('주택법'))
            {
                const num = isNaN((this.beSum/this.sangSum))? 0 : Math.round((this.beSum/this.sangSum)*1000)/1000;
                for(const o of this.personList)
                {
                    if(o.BIGO_NM == '비상주')
                    {
                        o.TUIP_GAEWOL_CN = num * this.getTotalMonths(this.selProj.BI_SIJAK_DT,this.selProj.BI_MAGAM_DT);
                    }
                }
            }
            else
            {
                for(const o of this.personList)
                {
                    if(o.BIGO_NM == '비상주')
                    {
                        o.TUIP_GAEWOL_CN = this.getBisangMMTotal();
                    }
                }
            }
        },
        getTotalMonths(startDate, endDate) {
        // 시작일과 마감일을 Date 객체로 변환
        const start = new Date(startDate);
        const end = new Date(endDate);

        // 총 개월 수 계산
        const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

        return totalMonths+1;
        },
        getBisangMMTotal()
        {
            return this.sigongMM*this.beRate/(100-this.beRate)/this.beCount;
        },
        searchCallback(row)
        {
            this.selSearch = row;
        },
        onChangeSelectBox()
        {
            this.$nextTick(()=>{
                this.pmGrid.refresh();
                this.pmGrid.repaint();
            })
        },
        onGijunChange()
        {
            for(const o of this.personList)
            {
                o.JIKJEOP_INGEONBI_RT = Number(this.cellCalc('JIKJEOP_INGEONBI_RT',o));
                o.INDANG_INGEONBI_AM = Number(this.cellCalc('INDANG_INGEONBI_AM',o));
                o.WOLGISEONG_AM = Number(this.cellCalc('WOLGISEONG_AM',o));
            }
            this.$nextTick(()=>{
                this.pmGrid.refresh();
            })
        },
        onCellPrepared(e)
        {
            if(e.rowType === 'data' &&  e.data != null)
            {
                const o = e.data;
                o.JIKJEOP_INGEONBI_RT = Number(this.cellCalc('JIKJEOP_INGEONBI_RT',o));
                o.INDANG_INGEONBI_AM = Number(this.cellCalc('INDANG_INGEONBI_AM',o));
                o.WOLGISEONG_AM = Number(this.cellCalc('WOLGISEONG_AM',o));
            }
        },
        async initSUJU()
          {
              const res = await RestHelper.RestSend('USP_SUJU','LIST',[{}]);
              LogHelper.logWrite('프로젝트 인당단가 수주 리스트 요청',[res],this.logUse);
              if(res._result[0].RESULTCD === '1')
              {
                  this.sujuList = [...res._data];
                  this.sujuList = this.sujuList.filter(x=>x.SUJU_HAKYUL_CD == "완료");
                  for(const o of this.sujuList)
                  {
                      o.SUJU_NAME = `${o.PJ_NM}(${o.PJCODE_ID})`;
                  }
                  this.sujuList.sort(function(a, b) {
                    return b.GYEYAKIL_DT - a.GYEYAKIL_DT;
                  });
              }
          },
          async initProject()
          {
              const res = await RestHelper.RestSend('USP_PJ_INDANGDANGA','SEARCH',[{}]);
              LogHelper.logWrite('프로젝트 인당단가 프로젝트 리스트 요청',[res],this.logUse);
              if(res._result[0].RESULTCD === '1')
              {
                  this.projList = [...res._data];
              }
          },
          async initInwon()
          {
              const res = await RestHelper.RestSend('USP_INWON_NEW','LIST',[{}]);
              LogHelper.logWrite('프로젝트 인당단가 인원 리스트 요청',[res],this.logUse);
              if(res._result[0].RESULTCD === '1')
              {
                  this.inwonList = [...res._data];
                  for(const o of this.inwonList)
                  {
                      o.INWON_NAME = `${o.SAWON_MYEONG_NM}(${o.BUSEOMYEONG_NM})`;
                  }
              }
          },
          async initOptions()
          {
              const res = await RestHelper.RestSend('USP_SUJU','INIT',[{}]);
              LogHelper.logWrite('프로젝트 인당단가 수주 옵션 리스트 요청',[res],this.logUse);
              if(res._result[0].RESULTCD === '1')
              {
                  this.optionList = [...res._data];
              }
          },
          async findProj()
          {
              this.selProj = this.projList.find(x=>x.SUJU_SQ ==this.selSearch.SUJU_SQ);
              this.selProj.PJ_BUNRYU_CD = this.selProj.PJ_BUNRYU_CD === '환산인원수' ? '환산인월수' : this.selProj.PJ_BUNRYU_CD
              this.selProj.SIJAK_DT = DataHelper.SetDtData(this.selProj.YONGYEOK_SIJAK_DT);
              this.selProj.MAGAM_DT = DataHelper.SetDtData(this.selProj.YONGYEOK_MAGAM_DT);
              this.selProj.BI_SIJAK_DT = DataHelper.SetDtData(this.selProj.BISANGJU_SIJAK_DT);
              this.selProj.BI_MAGAM_DT = DataHelper.SetDtData(this.selProj.BISANGJU_MAGAM_DT);
              const res = await RestHelper.RestSend('USP_PJ_INDANGDANGA','LIST',[{
                  SEARCH_KEYWORD : '',
                  PROJECT_SQ : this.selProj.PROJECT_SQ,
              }]);
              if(res._result[0].RESULTCD === '1')
              {
                this.tempPersonList = [...res._data];
                this.personList = [...res._data];
                let i = 0;
                for(const o of this.personList)
                {
                    o.index = i++;
                    o.JIKJEOP_INGEONBI_AM = Number(o.JIKJEOP_INGEONBI_AM);
                    o.JIKJEOP_INGEONBI_RT = Number(o.JIKJEOP_INGEONBI_RT);
                    o.INDANG_INGEONBI_AM = Number(o.INDANG_INGEONBI_AM);
                    o.TUIP_GAEWOL_CN = Number(o.TUIP_GAEWOL_CN);
                    o.WOLGISEONG_AM = Number(o.WOLGISEONG_AM);

                    o.JIKJEOP_INGEONBI_RT = Number(this.cellCalc('JIKJEOP_INGEONBI_RT',o));
                    o.INDANG_INGEONBI_AM = Number(this.cellCalc('INDANG_INGEONBI_AM',o));
                    o.WOLGISEONG_AM = Number(this.cellCalc('WOLGISEONG_AM',o));
                }
              }
          },
          getFilterOption(code)
          {
              return this.optionList.filter(x=>x.GRPCD == code);
          },
          onCellClick(e)
          {
              if(!e.data)return;
              this.selData = e.data;
              ClipboardHelper.keyExpr = 'index';
              ClipboardHelper.onCellClick(e);
          },
          onCellHoverChanged(e)
          {
              ClipboardHelper.keyExpr = 'index';
              ClipboardHelper.onCellHoverChanged(e);
          },
          onClickAdd()
          {
              if(DataHelper.StringNullorEmpty(this.selSearch.PJ_NM))return;
              this.$nextTick(()=>{
                  this.personList.push({
                      INWON_SQ : null,
                      BUNRYU_NM : "",
                      SAWON_MYEONG_NM : "",
                      JIKJEOP_INGEONBI_AM : "",
                      JIKJEOP_INGEONBI_RT : "",
                      INDANG_INGEONBI_AM : "",
                      TUIP_GAEWOL_CN : "",
                      WOLGISEONG_AM : "",
                      BIGO_NM : "상주",
                      index : this.personList.length
                  });
                  this.pmGrid.refresh();
              });
          },
          onBunryuBlur(e,data)
          {
            data.row.data.BUNRYU_NM = e.srcElement.value;
          },
          setINWON(data)
          {
              const inwon = this.inwonList.find(x=>x.INWON_NAME == data.displayValue)
              if(inwon)
              {
                  data.row.data.INWON_SQ = inwon.INWON_SQ;
              }
              return data.value;
          },
          onReorder(e)
          {
              const toIndex = e.fromIndex;
              const fromIndex = e.toIndex;
              const toObj = this.personList.find(x=>x.index == toIndex); 
              const fromObj = this.personList.find(x=>x.index == fromIndex);
              fromObj.index = toIndex;
              toObj.index = fromIndex;
              this.personList.sort((a, b) => a.index.toString().localeCompare(b.index))
              this.personList = [...this.personList]
          },
          cellCalc(field,rowData)
          {
              const rateValue = parseFloat(rowData.JIKJEOP_INGEONBI_AM / this.getTotalCost());
              if(field == 'JIKJEOP_INGEONBI_RT')
              {
                  let ret = `${Math.round(rateValue*10000)/100}`
                  return ret;
              }
              const INDANG_INGEONBI  = Math.round(rateValue*Number(this.selProj.GIJUN_GEUMAEK_AM));
              if(field == 'INDANG_INGEONBI_AM')
              {
                  let ret = INDANG_INGEONBI;
                  return ret;
              }
              if(field == 'WOLGISEONG_AM')
              {
                  let ret = Math.round(INDANG_INGEONBI/Number(rowData.TUIP_GAEWOL_CN));
                  return ret;
              }
              return 0;
          },
          getTotalCost()
          {
              let ret = this.personList.reduce((acc,curr)=>{
                  return Number(acc)+Number(curr.JIKJEOP_INGEONBI_AM);
              },0) 
              return ret;
          },
          async onClickSave()
          {
              const dataSet = [];
              for(const o of this.personList)
              {
                if(this.personList.filter(x=>(x.index != o.index)&&(x.INWON_SQ === o.INWON_SQ)&&(x.BIGO_NM === o.BIGO_NM)).length > 0)
                {
                    alert('근무형태가 중복되었습니다.')
                    return;
                }
                  const SIJAK_DT = this.selProj.SIJAK_DT?.replace(/-/g,'');
                  const MAGAM_DT = this.selProj.MAGAM_DT?.replace(/-/g,'');
                  const BI_SIJAK_DT = this.selProj.BI_SIJAK_DT?.replace(/-/g,'');
                  const BI_MAGAM_DT = this.selProj.BI_MAGAM_DT?.replace(/-/g,'');
                  if(DataHelper.StringNullorEmpty(SIJAK_DT)||
                  DataHelper.StringNullorEmpty(MAGAM_DT))
                  {
                      alert('용역 시작일과 마감일이 설정되지 않았습니다.')
                      return;
                  }
                  const rateValue = parseFloat(o.JIKJEOP_INGEONBI_AM / this.getTotalCost());
                  const INDANG_INGEONBI  = Math.round(rateValue*Number(this.selProj.GIJUN_GEUMAEK_AM));
                  const tempData = {
                      'SEARCH_KEYWORD' : '',
                      'PROJECT_SQ' : this.selProj.PROJECT_SQ,
                      'SUJU_SQ' : this.selProj.SUJU_SQ,
                      'PJCODE_ID' : this.selProj.PJCODE_ID,
                      'PROJECT_NM' : this.selSearch.PJ_NM,
                      'BUNRYUPJ_CD' : this.selProj.PJ_BUNRYU_CD == '직접인건비' ? '010' : '020',
                      'GIJUN_GEUMAEK_AM' : this.selProj.GIJUN_GEUMAEK_AM?.toString() ?? '',
                      'GYEYAK_GEUMAEK_AM' : this.selProj.GYEYAK_GEUMAEK_AM?.toString() ?? '',
                      'OEJUBI_AM' : '',
                      'YONGYEOK_SIJAK_DT' : SIJAK_DT,
                      'YONGYEOK_MAGAM_DT' : MAGAM_DT,
                      'BISANGJU_SIJAK_DT' : BI_SIJAK_DT,
                      'BISANGJU_MAGAM_DT' : BI_MAGAM_DT,
                      'OEJU_GIGAN_NM' : '',
                      'GYEYAGIL_DT' : this.selSearch.GYEYAKIL_DT,
                      'GYEYAK_CHASU_NM' : '',
                      'INDANGDANGA_SQ' : o?.INDANGDANGA_SQ ?? 0,
                      'INWON_SQ' : o.INWON_SQ,
                      'BUNRYU_NM' : o.BUNRYU_NM,
                      'JIKJEOP_INGEONBI_AM' : o.JIKJEOP_INGEONBI_AM,
                      'JIKJEOP_INGEONBI_RT' : o.JIKJEOP_INGEONBI_RT,
                      'TUIP_GAEWOL_CN' : o.TUIP_GAEWOL_CN,
                      'BIGO_NM' : o.BIGO_NM,
                      'INDANG_INGEONBI_AM' : INDANG_INGEONBI,
                      'WOLGISEONG_AM' : Math.round(INDANG_INGEONBI/Number(o.TUIP_GAEWOL_CN)),
                      'BUNRYU_CD' : this.optionList.filter(x=>x.GRPCD == 'BUNRYU').find(x=>x.NAME == this.selProj.BUNRYU_CD)?.CODE,
                  };
                  dataSet.push(tempData);
              }
              if(dataSet.length < 1)
              {
                  alert('프로젝트 최소 인원 1명이 필요합니다.')
                  return;
              }
            const delList = this.tempPersonList.filter(x=>!this.personList.find(o=>o.INDANGDANGA_SQ == x.INDANGDANGA_SQ))
            await this.delPerson(delList);
            const res = await RestHelper.RestSend("USP_PJ_INDANGDANGA","INSERT",dataSet);
            if(res._result[0].RESULTCD === '1')
            {
                alert('저장')
            }
            else
            {
                alert('저장 실패')
            }
          },
          async delPerson(arr)
          {
            if(arr.length < 1)return;
            const dataSet = [];
            for(const o of arr)
            {
                if(DataHelper.StringNullorEmpty(o.INDANGDANGA_SQ.toString()))continue;
                const tempData = {
                      'SEARCH_KEYWORD' : '',
                      'PROJECT_SQ' : '',
                      'SUJU_SQ' : '',
                      'PJCODE_ID' : '',
                      'PROJECT_NM' : '',
                      'BUNRYUPJ_CD' : '',
                      'GIJUN_GEUMAEK_AM' : '',
                      'GYEYAK_GEUMAEK_AM' : '',
                      'OEJUBI_AM' : '',
                      'YONGYEOK_SIJAK_DT' : '',
                      'YONGYEOK_MAGAM_DT' : '',
                      'BISANGJU_SIJAK_DT' : '',
                      'BISANGJU_MAGAM_DT' : '',
                      'OEJU_GIGAN_NM' : '',
                      'GYEYAGIL_DT' : '',
                      'GYEYAK_CHASU_NM' : '',
                      'INDANGDANGA_SQ' : o.INDANGDANGA_SQ,
                      'INWON_SQ' : '',
                      'BUNRYU_NM' : '',
                      'JIKJEOP_INGEONBI_AM' : '',
                      'JIKJEOP_INGEONBI_RT' : '',
                      'TUIP_GAEWOL_CN' : '',
                      'BIGO_NM' : '',
                      'INDANG_INGEONBI_AM' : '',
                      'WOLGISEONG_AM' : '',
                      'BUNRYU_CD' : '',
                  };
                  dataSet.push(tempData);
            }
            const res = await RestHelper.RestSend("USP_PJ_INDANGDANGA","DELETE",dataSet);
            if(res._result[0].RESULTCD === '1')
            {
                return;
            }
          }
      }
  }
  </script>
  
  <style>
  #PJ_view .dx-datagrid .dx-datagrid-headers .dx-header-filter,
  #PJ_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
      outline: 0;
      padding: 0;
      height: 47px;
      font-size: 1.3rem;
      color:#818181;
      font-weight: 500;
      vertical-align: middle;
  }
  #PJ_view .cell-selected{
      background: rgb(204, 217, 238) !important;
  }
  #PJ_view .dx-texteditor.dx-editor-filled .dx-texteditor-input {
      padding: 0;
      border: 0;
      border-radius: 0;
      color:#818181;
      padding-left:8px;
      font-size: 1.4rem;    
      height:25px;
  }
  #PJ_view .dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
      vertical-align: middle;
      text-align: center !important;
  }
  #PJ_view .dx-texteditor.dx-editor-filled {
      vertical-align: middle;
      line-height:0;
      padding: 0;
      background:white;
  }
  #PJ_view .dx-placeholder {
      text-align: left;
  }
  #PJ_view .dx-texteditor-input-container {
      display: flex;
      flex-direction: row;
      align-items:center;
  }
  #PJ_view .dx-texteditor.dx-editor-filled::after {
      border-bottom:0;
  }
  #PJ_view .dx-datagrid .dx-gridbase-container .dx-datagrid-borders {
      border:2px solid #c7c7c7
  }
  #PJ_view  .dx-datagrid .dx-row-lines > td {
      border: 1px solid #e0e0e0;
  }
  #PJ_view .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
      padding: 0 8px;
      font-size: 1.3rem !important;
      background: #fafafa;
  }
  #PJ_view .dx-freespace-row {
    display: none;
  }
  #PJ_view .dx-scrollable-container {
    height:100%;
  }
  #PJ_view .dx-scrollable-content {
    height:100%;
  }
  #PJ_view .dx-freespace-row {
    display: none;
  }
 #PJ_view .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper {
    height: 100%;
 }
 #PJ_view .dx-scrollable-content::after {
    display: none;
 }

 #PJ_view .dx-datagrid-total-footer > .dx-datagrid-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.dx-selectbox-container {
    border: 1px solid #c7c7c7 !important;
    width: 100%;
}
  </style>