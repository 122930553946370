import DataHelper from "../Helpers/DataHelper";
export default class DpModel{
    constructor()
    {
        this.STARTYEAR = ""; //시작년도
        this.ENDYEAR = ""; //마감년도
        this.USE_YN = ""; //마감여부
        this.BUSEO_SQ = "";  // 부서시퀀스
        this.BUSEOMYEONG_NM = ""; //부서명
        this.BONBUMYEONG_NM = ""; //본부명
        this.BUSEO_SIJAK_DT = ""; //시작일
        this.BUSEO_MAGAM_DT = ""; //마감일
        this.BUSEO_COLOR_NM = null; //본부 컬러
        this.BUSEO_BYEONGYEONG_ID = ""; //부서변경ID
        this.MERGE_ID = "";
    }

    getType()
    {
        if(this.BONBUMYEONG_NM == null){
            return 'BONBU'
        }
        return 'BUSEO'
    }

    setEditMod()
    {
        if(!DataHelper.StringNullorEmpty(this.BUSEO_SIJAK_DT))
        {
            this.BUSEO_SIJAK_DT = this.BUSEO_SIJAK_DT?.replace(/-/g,'');
            this.BUSEO_SIJAK_DT = DataHelper.SetDtData(this.BUSEO_SIJAK_DT)
        }
        if(!DataHelper.StringNullorEmpty(this.BUSEO_MAGAM_DT))
        {
            this.BUSEO_MAGAM_DT = this.BUSEO_MAGAM_DT?.replace(/-/g,'');
            this.BUSEO_MAGAM_DT = DataHelper.SetDtData(this.BUSEO_MAGAM_DT)
        }
    }

    copy(target)
    {
        for(const key of Object.keys(target))
        {
            this[key] = target[key];
        }
    }
}